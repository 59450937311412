import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss';
import { Row, Col, Button, Form, Input, Typography } from 'antd';

import CountrySelectComponent from '../../../../common/CountrySelectComponent';

const { Title } = Typography;

const styles = (theme) => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
});

function UserAddressContainer(props) {
  const { updateApplicantHandler, applicant, classes } = props;

  const [addressInfo, setAddressInfo] = useState({});

  useEffect(() => {
    setAddressInfo((prev) => ({
      ...prev,
      ...applicant,
    }));
  }, [applicant]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    updateApplicantHandler(addressInfo);
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setAddressInfo((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleChangeStreetCountry = (value) => {
    setAddressInfo((prev) => ({
      ...prev,
      street_address_country: value,
    }));
  };

  const handleChangePostalCountry = (value) => {
    setAddressInfo((prev) => ({
      ...prev,
      postal_address_country: value,
    }));
  };

  return (
    <React.Fragment>
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            <Title level={4}>Street Address</Title>
            <Form.Item
              label="Street Address (Line 1)"
              style={{ marginBottom: 0 }}
              className={classes.form_label}
            >
              <Input
                id="street_address_line_1"
                value={addressInfo.street_address_line_1}
                onChange={(event) => handleChange(event)}
              />
            </Form.Item>
            <Form.Item
              label="Street Address (Line 2)"
              style={{ marginBottom: 0 }}
              className={classes.form_label}
            >
              <Input
                id="street_address_line_2"
                value={addressInfo.street_address_line_2}
                onChange={(event) => handleChange(event)}
              />
            </Form.Item>
            <Row gutter={[12, 0]}>
              <Col xs={12}>
                <Form.Item
                  label="Suburb"
                  style={{ marginBottom: 0 }}
                  className={classes.form_label}
                >
                  <Input
                    id="street_address_suburb"
                    value={addressInfo.street_address_suburb}
                    onChange={(event) => handleChange(event)}
                  />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  label="State"
                  style={{ marginBottom: 0 }}
                  className={classes.form_label}
                >
                  <Input
                    id="street_address_state"
                    value={addressInfo.street_address_state}
                    onChange={(event) => handleChange(event)}
                  />
                </Form.Item>
              </Col>
              <Col xs={4}>
                <Form.Item
                  label="PostCode"
                  style={{ marginBottom: 0 }}
                  className={classes.form_label}
                >
                  <Input
                    id="street_address_postcode"
                    value={addressInfo.street_address_postcode}
                    onChange={(event) => handleChange(event)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Country" className={classes.form_label}>
              <CountrySelectComponent
                id="street_address_country"
                value={addressInfo.street_address_country}
                handleChange={handleChangeStreetCountry}
              />
            </Form.Item>
            <br />
          </Col>
          <Col xs={12}>
            <Title level={4}>Postal Address</Title>
            <Form.Item
              label="Postal Address (Line 1)"
              style={{ marginBottom: 0 }}
              className={classes.form_label}
            >
              <Input
                id="postal_address_line_1"
                value={addressInfo.postal_address_line_1}
                onChange={(event) => handleChange(event)}
              />
            </Form.Item>
            <Form.Item
              label="Postal Address (Line 2)"
              style={{ marginBottom: 0 }}
              className={classes.form_label}
            >
              <Input
                id="postal_address_line_2"
                value={addressInfo.postal_address_line_2}
                onChange={(event) => handleChange(event)}
              />
            </Form.Item>
            <Row gutter={[12, 0]}>
              <Col xs={12}>
                <Form.Item
                  label="Suburb"
                  style={{ marginBottom: 0 }}
                  className={classes.form_label}
                >
                  <Input
                    id="postal_address_suburb"
                    value={addressInfo.postal_address_suburb}
                    onChange={(event) => handleChange(event)}
                  />
                </Form.Item>
              </Col>
              <Col xs={8}>
                <Form.Item
                  label="State"
                  style={{ marginBottom: 0 }}
                  className={classes.form_label}
                >
                  <Input
                    id="postal_address_state"
                    value={addressInfo.postal_address_state}
                    onChange={(event) => handleChange(event)}
                  />
                </Form.Item>
              </Col>
              <Col xs={4}>
                <Form.Item
                  label="PostCode"
                  style={{ marginBottom: 0 }}
                  className={classes.form_label}
                >
                  <Input
                    id="postal_address_postcode"
                    value={addressInfo.postal_address_postcode}
                    onChange={(event) => handleChange(event)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item label="Country" className={classes.form_label}>
              <CountrySelectComponent
                id="postal_address_country"
                value={addressInfo.postal_address_country}
                handleChange={handleChangePostalCountry}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[96, 24]}>
          <Col xs={24} style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit" onClick={handleSubmit}>
              Save Record
            </Button>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  );
}

export default withStyles(styles, { injectTheme: true })(UserAddressContainer);
