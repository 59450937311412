import React, {
  useEffect, useState
} from 'react';
import { connect } from 'react-redux';
import { Table, Spin, Button, Col, Tag } from 'antd';
import { NavLink } from 'react-router-dom';

import { getSavedJobSearches, updateSavedSearchActive } from '../../../../redux/actions/jobs';

const JobSavedSesarchesComponent = (props) => {
  const { getSavedJobSearchesHandler, savedJobSearchesList, updateSavedSearchActiveHandler } = props;
  const [savedSearchesList, setSavedSearchesList] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleGetJobProfiles = async () => {
    setLoading(true);
    await getSavedJobSearchesHandler();
    setLoading(false);
  };

  useEffect(() => {
    handleGetJobProfiles();
  }, []);
  useEffect(() => {
    if (savedJobSearchesList.length > 0)
      setSavedSearchesList(savedJobSearchesList);
  }, [savedJobSearchesList]);

  const handleChangeActive = async (params) => {
    setLoading(true);
    await updateSavedSearchActiveHandler(params);
    setLoading(false);
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: '10%'
    },
    {
      title: 'Search Name',
      dataIndex: 'nickname',
      width: '40%'
    },
    {
      title: 'Active',
      dataIndex: 'active',
      width: '30%',
      render: tag => (
        <Tag color={tag ? 'green' : 'red'} key={tag}>
          {tag ? 'Yes' : 'No'}
        </Tag>
      ),
    },
    {
      title: 'Apply Search',
      key: 'action',
      width: '20%',
      render: (text, record) => {
        const params = JSON.parse(record.searchFilters);
        const { userinput_classification, userinput_country, userinput_region, userinput_keywords } = params;
        return (
          <span>
            <NavLink to={`/jobs/?what=${userinput_keywords}&classification=${userinput_classification}&region=${userinput_region}&country=${userinput_country}`} >View</NavLink>
            <Button
              type='primary'
              onClick={() => handleChangeActive({ id: record.id, active: !record.active })}
              style={{ marginLeft: 30 }}>{record.active ? 'Inactive' : 'Active'}
            </Button>
          </span>
        )
      },
    },
  ];

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Table
          columns={columns}
          dataSource={savedSearchesList}
        />
      </Spin>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    savedJobSearchesList: state.jobs.savedJobSearches,
  }
};

const mapDispatchToProps = ({
  getSavedJobSearchesHandler: getSavedJobSearches,
  updateSavedSearchActiveHandler: updateSavedSearchActive,
});

export default connect(mapStateToProps, mapDispatchToProps)(JobSavedSesarchesComponent);
