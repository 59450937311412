export default {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'com.forexworldwide.crm.documents.01'
  },
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://9s3zyajdba.execute-api.ap-southeast-2.amazonaws.com/dev'
  },
  cognito_applicants: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_qNmDotQ0t',
    APP_CLIENT_ID: '6i7312jfoetsq2ccmkum82ipg2',
    IDENTITY_POOL_ID: 'us-east-1:6fd778cd-e84b-4fe6-a787-65ea5df91ff0'
  }
};
