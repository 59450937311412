import { companiesType } from '../type';

const initialState = {
  companies: [],
  savedCompaniesCount: 0,
  savedCompanies: [],
};

function companies(state = initialState, action) {
  switch (action.type) {
    case companiesType.GET_COMPANIES:
      return {
        ...state,
        companies: action.companies,
      };
    case companiesType.GET_SAVED_COMPANIES:
      return {
        ...state,
        savedCompanies: action.savedCompanies,
        savedCompaniesCount: action.savedCompaniesCount,
      };
    default:
      return state;
  }
}

export default companies;
