import React from 'react';

import {
  Card,
  PageHeader,
} from 'antd';

import PageTitle from '../../../components/page-title/page-title';
import JobProfileListViewComponent from '../../../components/pages/dashboard/job_profile/JobProfileListViewComponent';

const JobProfileListView = () => {

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageTitle title="My Profiles">
        On this page you can manage your Job Application Profiles.  You can view and edit existing profiles and create new ones.  You may create as many profiles as you wish.
      </PageTitle>
      <Card
        style={{ minHeight: 360 }}
        title=''
      >
        <div style={{ paddingTop: 24 }}>
          <JobProfileListViewComponent />
        </div>
      </Card>
    </div>
  );
}

export default React.memo(JobProfileListView);
