import React, {useState, useEffect} from 'react';
import {getDocuementAPI} from '../../../../services/documents';
import {Spin, Row, Col, Space, Typography} from 'antd';
import {useParams} from 'react-router-dom';

const {Text} = Typography;
const categories = [
  {id: 1, file_by: 'Curriculum vitae'},
  {id: 2, file_by: 'Application Cover Letter'}
];

const DocumentViewComponent = props => {
  const [loading, setLoading] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const {id} = useParams();
  useEffect(() => {
    if (id) {
      setLoading(true);
      getDocuementAPI(id)
        .then(data => {
          setLoading(false);
          setDocumentData(data.data);
        })
        .catch(error => {
          setLoading(false);
          setDocumentData({});
        });
    }
  }, [id]);

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row gutter={[16, 16]}>
              <Col span={7}>
                <Text>Document Category:</Text>
              </Col>
              <Col span={8}>
                <Text>{categories.find(item => item.id === documentData.documentCategory)?.file_by}</Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={7}>
                <Text>Document Title:</Text>
              </Col>
              <Col span={8}>
                <Text>{documentData.documentTitle}</Text>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={7}>
                <Text>Document</Text>
              </Col>
              <Col span={8}>
                {documentData.fileId && (
                  <a download='foo.txt' target='_blank' href={JSON.parse(documentData.fileId)?.path}>
                    {JSON.parse(documentData.fileId)?.name}
                  </a>
                )}
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Space size={10} direction='vertical' style={{width: '100%'}}>
              <Text>Long Description:</Text>
              <Text>{documentData.longDescription}</Text>
            </Space>
          </Col>
        </Row>
      </Spin>
    </React.Fragment>
  );
};

export default DocumentViewComponent;
