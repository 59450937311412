import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Spin, Button } from 'antd';
import { NavLink } from 'react-router-dom';

import {getDocuments} from '../../../../redux/actions/documents';
import '../../../../style/table.scss';

const columns = [
  {
    title: 'Category',
    render: (text, record) => record.documentCategorySub?.fileBy
  },
  {
    title: 'Title',
    dataIndex: 'documentTitle'
  },
  {
    title: 'Actions',
    key: 'action',
    render: (text, record) => (
      <span>
        <NavLink to={`/profile/document-view/${record.id}`}>View</NavLink>
      </span>
    )
  }
];

const DocumentListViewComponent = props => {
  const dispatch = useDispatch();
  const documents = useSelector(state => state.documents);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleGetJobProfiles = async () => {
      setLoading(true);
      await dispatch(getDocuments());
      setLoading(false);
    };
    handleGetJobProfiles();
  }, [dispatch, setLoading]);

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Table className="custom-table" columns={columns} dataSource={documents || []} rowKey='documents' />
      </Spin>
      <div style={{
        textAlign: 'right',
        paddingTop: 30
      }}>
        <Button type="primary" style={{ marginBottom: 30 }}>
          <NavLink to="/profile/document-create">Add Document</NavLink>
        </Button>
      </div>
    </React.Fragment>
  );
};

export default React.memo(DocumentListViewComponent);
