import React from 'react';
import { Card, PageHeader } from 'antd';
import { Collapse, Space } from 'antd';

const { Panel } = Collapse;

const panelContent = {
  'What’s PlatinumJobSeeker™’s philosophy?': [
    'We have two cornerstones to our ethos.',
    'For Jobseekers: every member jobseeker gets a response to every Platinum Tick™ job application, everytime.Our member jobseekers get innovative tools to help them with their job searching.',
    'For Job advertisers: we provide a raft of innovative and cost-effective tools to attract the right people, and our loyal member jobseekers already stand out from the rest because they are better     informed, and better pre-qualified. It’s a strong value proposition.',
  ],
  'Why do jobseekers pay a fee on Platinum Job Seeker?': [
    'Our jobseeker members receive services which have never been offered to them by other job portals, and features and benefits which are unique to us. Every jobseeker has value to us. We believe that once you have used our services, you will see that there is enormous value in what we offer, and that great communication is key to the process.',
    'Our friendly and knowledgeable customer services reps are available to answer your questions',
  ],
  'What does a monthly jobseeker subscription cost?': [
    'Two AUD ($2 AUD) per jobseeker member per month for a standard PJS Basic 30 (30 day) subscription. There are no hidden fees.',
    'One month is 30 consecutive days',
    'Early terminations do not receive refunds of unused portions of the Jobseeker membership subscription.',
    'Jobseekers may also choose to prepay 3 or 6 months in advance. That’s $5 AUD for a PJS Basic 90 (90 day) subscription and $8 AUD for a PJS Basic 180 (180 day) subscription .',
    'The $5 AUD Basic 90 prepayment option for a membership subscription is the most popular and lasts for 90 consecutive days, and it also saves you the hassle of re-subscribing every month!',
    'For further information on subscription services, please rate chart at the bottom of this page.'
  ],
  'What does the subscription fee get me?': [
    'PlatinumJobSeeker™ members can:',
    '• Perform unlimited job searches',
    '• Choose to make your profile public – which means available to headhunters (if you are open to an unsolicited job offer)',
    '• Choose to keep your profile private, or available only to the people to whom you send your applications',
    '• Load up to 5 different versions of your CV and have access to CV templates (the saving of the sixth CV will delete the prior version of your CV, oldest first)',
    '• Load up to two different cover letters',
    '• Apply direct for Platinum Tick™ jobs',
    '• Load a pitch video which is posted to your profile after being verified by the site for conformity with our content rules (basically racist, inciteful, inflammatory or disrespectful or false content is forbidden)',
    '• Gain access to pitch videos from your chosen companies',
    '• Unlimited access to proforma documents to help you with your applications',
    '• A guarantee of a response to your applications to Platinum Tick™ jobs',
    '• Multiple saved searches with mail out / update option',
    '• Access to courses for upskilling through our affiliate partners',
    '• Hints and tips on relevant topics such as resume writing and CV structure',
    '• Topical relevant articles on industry perspectives, employment sector analysis and job trends',
    '• Pre-screening of your application for relevance to the advertised Platinum Tick™ job criteria',
    '• Hints and tips for jobseeker members on interview preparation, video pitching, and accessibility to head hunters',
    '• Targeted searches using more refined criteria to eliminate false positives and useless or irrelevant search results',
    '• Access to Platinum Tick™ jobs as well as jobs from other websites for broader coverage and stronger search results. Note here that PlatinumJobSeeker™ can not guarantee a response to your applications performed off-site to jobs that are not listed directly with us',
    '• More informative Platinum Tick™ job ads showing indicative Platinum Tick™ job start date, reporting lines and vital job information',
    '• More ethical treatment for Platinum Tick™ job ad applicants by a company which understands and values that you spend your time researching, deciding to apply, creating cover letters, and creating targeted CVs - and you deserve a response. Whilst not every application will receive a positive response, our view is that it is better to know, and to move on to the next opportunity with a clear mindset - rather than waste what seems like an eternity for a response that may never happen.',
  ],
  'What happens when my subscription runs out or I do not renew it?': [
    `If your subscription runs out, PlatinumJobSeeker™ retains the information you place on our site. An expired member can reactivate their account at any time. However while your account is de-activated, headhunters will not be able to see your details, and you will not be able to use the features of the site to their fullest. We do not impose any financial penalty for deactivation. Simply renew your subscription (for as little as AUD $2!) and you are back up and running again. You control your destiny. All site subscribed member features and functions are opened up to you again when you re-activate your subscription, and this is true any number of times that you deactivate or reactivate. Don’t worry - PlatinumJobSeeker™ will send you a reminder that your membership is going to expire 7 days before the actual expiry date, leaving you plenty of time to get your next membership period paid up. Because deactivation and reactivation is a delayed process, we strongly suggest that to have seamless services from us, you choose the 3 or 6 month subscription payment option. Longer subscriptions reduce the number of emails you will get from our site administration. A 1 month subscriber can renew for 1, 3 or 6 months. A 3 or 6 month subscriber can renew for 1, 2 or 3 months as well. Payments are for consecutive periods, so if you subscribe for 30 days on day 1, and then subscribe for an additional 30 days on day 28, that means a full 60 days of continuous membership for you. We do not reimburse for any unused portion of the subscription period for which you have prepaid. Just remember that the longer you prepay, the less you pay per month!`,
  ],
  'What happens if the site administrators catch me posting inappropriate content?': [
    `We don’t have a “three strikes” policy. If our admin reasonably determines that you are not who claim to
    be, if you post or try to post inappropriate content (please see {<a href='/legal/terms-conditions' target='_blank'>here</a>} for a list of
    what is inappropriate), you will be permanently banned from the site, you will not receive any refund of
    your unused subscription payment, and we will advise any relevant government authority that directs us
    to do so, of your identity details. Our site is for serious jobseekers. We do not want you wasting your
    time, our time, or the time of advertisers who are looking for job candidates of the highest quality.
    `,
    `PlatinumJobSeeker™ is a professional platform of the highest integrity. Our advertisers and our
    jobseekers rely on it.`,
    `Troll elsewhere, please.`
  ],
  'Who do I contact if I have an issue or comment/suggestion?': [
    'Our FAQs are the responses to the most commonly asked questions.',
    'If you have checked here first, and still have no appropriate response, please use the following contact details:',
    `For payment and subscription issues: <a href='mailto:admin@platinumjobseeker.com'>admin@platinumjobseeker.com</a>`,
    `For site related issues (not loading, too slow, fields don’t work, forms not loading – which we call UE
      matters), please email <a href='mailto:UE@platinumjobseeker.com'>UE@platinumjobseeker.com</a>`,
    `Want to make a suggestion or comment? Email <a href='mailto:suggestions@platinumjobseeker.com'>suggestions@platinumjobseeker.com</a>`,
  ],
  'Does the site warn me that my subscription is about to expire so I can forget about it until then?': [
    `Yes. We remind you once, 7 days before the expiry date. You will also be able to manage your
    subscription in the applicant portal under “subscriptions”.`,
  ],
  'How do I use the pitch video function for member applicants?': [
    'Use of the member applicant pitch video function is optional.',
    `Simply take a video in .jpeg format which is no longer than 15 seconds. Load the video to your supported
    device. Drag and drop the video into the pitch video upload section of the applicant portal.`,
    `You control the access to your video. You can choose to send it to the people you apply to via an
    embedded link in your application for Platinum Tick™ jobs. This is not available for jobs without the
    Platinum Tick™.`,
    `When moderated by the site for allowable content, the video is posted to the site and sits on your
    profile subject to the access to it that you want to give for the term of your membership subscription.
    Want to replace it with another pitch video? Feel free to do so as many times as you wish - but there is
    only one pitch video on your profile at any one time. Your replacement pitch video will permanently
    delete the prior version. You will be notified when an appropriate video is uploaded. If the video
    content is inappropriate, we reserve the right not to upload it, and in certain circumstances to delete
    your account for a serious breach of our terms and conditions.`,
    'Pitch videos are your way of introducing yourself to employers and agencies.',
    `Present yourself appropriately to prospective agencies and employers. Use a clear voice, decent
    lighting, and talk to the camera as if it was your contact person. Use the video to highlight your
    strengths and support your claims in your CV. Don’t pretend to be someone else, or submit a video of you
    speaking on another person’s behalf. Treat this valuable resource with respect and integrity – it is
    designed to help to make you stand out as an applicant for the right reasons, not the wrong ones.`,
    `An example of an appropriate pitch video is <a href=''>here</a>.`
  ],
  'What happens if I apply for a job on the PlatinumJobSeeker™ site, and I don’t get any response?': [
    `Our commitment of a guaranteed response to applicants are for our paid up jobseeker members only, and for
    Platinum Tick™ job ads only.`,
    `Why? We can not control what other websites do, or what employers or agents do outside the scope of our own
    site.`,
    `Platinum Tick™ jobs are different.`,
    `Platinum Tick™ jobs are those job ads that are placed directly with our site. If a job ad does not have the
    Platinum Tick™ symbol, then the site does not guarantee that the agent or advertiser will get back to you with
    any response at all, because responses to those ads are outside the scope of our ecosystem. Clicking on such
    ads generally take you outside of our ecosystem, and direct you to the site of the advertiser or employer
    where the ad was placed. We display those ads on our site to merely provide a fuller option to our jobseeker
    members.`,
    `If a job ad does have the Platinum Tick™ symbol, then that’s where we come in. Agents and employers are given
    powerful tools to get back to every applicant, and our system reminds them that a response has been sent, is
    pending, or is overdue. Our site responds to unsuccessful applicants, and also advises applicants when their
    application has been opened by the agent or advertiser. Our terms and conditions require that agents and
    advertisers either authorise us to advise unsuccessful applicants, or that the advertiser or agent commits to
    establishing direct contact with jobseekers, and then to prove that they have done so.`,
    `So – for Platinum Tick™ job ads, either we, or the agent or employer WILL get back to you. Quickly. Unactioned
    applications are reminded to the agent or advertiser every 48 hours. We keep doing this until the agent or
    advertiser shows us that a response has been sent. Your applicant portal and dashboard will show that the
    response has been sent to you via your email in, or by direct telephone contact from the agent or employer.`
  ]
};
const FAQPAge = () => {
  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='FAQ'
      />
      <Card style={{ minHeight: 360 }} title='Frequently Asked Questions'>
        <div style={{ paddingTop: 24 }}>
          <p>
            PlatinumJobSeeker™ is a unique site where committed and capable jobseekers can search out their next job
            opportunity with both traditional and innovative tools. Whether you are an experienced candidate, or new to
            the job market, PlatinumJobSeeker™ is committed to providing our jobseekers with ethical services, and our
            job advertisers with cost effective, powerful tools to attract the right people.
          </p>
          <Collapse defaultActiveKey={['0']} onChange={() => { }}>
            {
              Object.keys(panelContent).map((item, index) => (
                <Panel header={<span style={{ fontWeight: 600 }}>{item}</span>} key={index}>
                  <Space size={10} direction="vertical">
                    {panelContent[item].map(content => (
                      <div key={content} dangerouslySetInnerHTML={{ __html: content }} />
                    ))}
                  </Space>
                </Panel>
              ))
            }
            <Panel header="Rate Chart" key={'faqratechart'}>
                  <Space size={10} direction="vertical">
                    <div key={'faqratechartcontent'}>
                      <img src={require('./pjs-applicant-rate-chart.png')} width="100%"/>
                    </div>
                  </Space>
                </Panel>
          </Collapse>
        </div>
      </Card>
    </div>
  );
};

export default FAQPAge;
