import React, { Component } from 'react';
import withStyles from 'react-jss';

// import { API, Auth } from "aws-amplify";
// import { Layout, Menu } from 'antd';
// import { Input } from 'antd';
import { PageHeader } from 'antd';
// import { Card } from 'antd';
import LoginPageComponent from '../../components/pages/pages/LoginPageComponent';
import { FormattedMessage } from 'react-intl';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;
const styles={

}
class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const {classes} = this.props

    return (
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title={<FormattedMessage id="login_page.main_title"/>}
        // subTitle="This is a subtitle"
        />
        {/* <DatePickerSample/> */}
        <LoginPageComponent
          isAuthenticated={this.props.isAuthenticated}
          userHasAuthenticated={this.props.userHasAuthenticated}
        />
      </div>
    );
  }
}

export default withStyles(styles)(LoginPage);
