import { applicationsType } from '../type';
import { getApplicationsAPI, createApplicationsAPI } from '../../services/applications';

/* Get all applications  */
const getApplications = (params) => {
  return (dispatch) => {
    return getApplicationsAPI(params)
      .then((data) => {
        if (data) {
          dispatch({
            type: applicationsType.GET_APPLICATIONS,
            applications: data.data
          });
        } // TODO Error Action Calling
      })
      .catch(error => {
        // TODO Error Action Calling
      })
  }
};

const createApplication = (params) => {
  return (dispatch) => {
    console.log('params:', params);
    return createApplicationsAPI(params)
      .then((data) => { 
        if (data) {
          dispatch({
            type: applicationsType.CREATE_APPLICATION,
            application: data.data
          });
        }
      })
      .catch(error => {
        // TODO Error Action Calling
      })
  }
};

export {
  getApplications,
  createApplication,
};
