import React, { useEffect, useState } from 'react';
import { Row, Col, Spin, Typography, Space } from 'antd';
import { applicantSubscriptionAPI } from '../../../../../services/payment';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const { Text } = Typography;
function MySubscriptionContainer({ tab }) {
  const [isLoading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState({});
  const history = useHistory();

  useEffect(() => {
    applicantSubscriptionAPI()
      .then((data) => {
        setSubscription(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (tab === 'create_subscription' || !subscription)
      history.push('/settings/subscription/1?tab=create_subscription');
  }, [tab, subscription]);

  let termText = '';
  switch (subscription?.subscription_price?.month_count) {
    case 1:
      termText = '1 Month Subscription Term';
      break;
    case 3:
      termText = '3 Month Subscription Term';
      break;
    case 6:
      termText = '6 Month Subscription Term';
      break;
  }

  return (
    <React.Fragment>
      <Spin spinning={isLoading}>
        <Space size={10} direction="vertical" style={{ width: '100%' }}>
          <p>Details of your current subscription are listed below.</p>
          <Row>
            <Col span={4}>Created:</Col>
            <Col span={18}>
              {moment(subscription?.record_created).format('DD/MM/YYYY')}
            </Col>
          </Row>
          <Row>
            <Col span={4}>Subscription Product:</Col>
            <Col span={18}>
              {subscription?.subscription_product?.file_by}{' '}
              {subscription
                ? subscription?.subscription_price?.month_count * 30 || 30
                : ''}
            </Col>
          </Row>
          <Row>
            <Col span={4}>Subscription Term:</Col>
            <Col span={18}>
              {subscription
                ? `${
                    subscription?.subscription_price?.month_count * 30 || 30
                  } days`
                : ''}
            </Col>
          </Row>
          <Row>
            <Col span={4}>Status:</Col>
            <Col span={18}>
              {subscription?.admin_subscription_status?.file_by}
            </Col>
          </Row>
        </Space>
      </Spin>
    </React.Fragment>
  );
}

export default MySubscriptionContainer;
