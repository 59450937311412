import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const getApplicationsAPI = (params) => {
  const path = `/applicant/v1/applications`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const createApplicationsAPI = (params) => {
  const path = `/applicant/v1/applications`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const isJobAppliedAPI = (params) => {
  const path = `/applicant/v1/applications/is-job-applied`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const updateApplicationsAPI = (id, params) => {
  const path = `/applicant/v1/applications/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

export {
  getApplicationsAPI,
  createApplicationsAPI,
  isJobAppliedAPI,
  updateApplicationsAPI,
};
