import React from 'react';
import { Select } from 'antd';
import withStyles from 'react-jss';
import { useSelector } from 'react-redux';

const styles = (theme) => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
});

const { Option } = Select;

const priorityStyle = {
  margin: '0 10px',
  marginBottom: 20,
  borderBottom: '2px solid rgb(123 123 123)',
  paddingBottom: 10,
};
const normalStyle = {
  margin: '0 10px',
};

function CountrySelectComponent(props) {
  const countries = useSelector(state => state.countries);
  const { value, handleChange, id } = props;
  const priorityCount = countries?.filter((item) => item.priority).length;

  const options = countries?.map((d, index) => (
    <Option
      key={`countries-${index}`}
      value={d.id}
      style={index === priorityCount - 1 ? priorityStyle : normalStyle}
    >
      {d.full_name}
    </Option>
  ));

  return (
    <Select
      id={id}
      showSearch
      style={{ flex: 1 }}
      value={value}
      placeholder="Select Country"
      showArrow={true}
      optionFilterProp="children"
      dropdownClassName="common-country-select-dropdown"
      filterOption={(input, option) => {
        return option.children.toString()
          .toLowerCase()
          .indexOf(input.toString().toLowerCase()) >= 0
        }
      }
      onChange={handleChange}
    >
      {options}
    </Select>
  );
}

export default withStyles(styles, { injectTheme: true })(CountrySelectComponent);
