import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const ADZUNA_APP_ID = process.env.REACT_APP_ADZUNA_APP_ID;
const ADZUNA_API_KEY = process.env.REACT_APP_ADZUNA_API_KEY;

const searchAdzunaJobsAPI = (whatKey, whereKey, page, region, pageSize) => {
  const path = `https://api.adzuna.com/v1/api/jobs/${whereKey}/search/${page}?app_id=${ADZUNA_APP_ID}&results_per_page=${pageSize}&app_key=${ADZUNA_API_KEY}${whatKey && `&what=${whatKey}`}${region && `&where=${region}`}`;
  const url = `${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendRequest();
};

export { searchAdzunaJobsAPI };
