import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Tabs } from 'antd';

import ApplicantSummary from './applicant_view/ApplicantSummary';
import ApplicantContact from './applicant_view/ApplicantContact';
import ApplicantAddress from './applicant_view/ApplicantAddress';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

class ApplicantNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {current_step: 0,}
  }

  componentDidMount() {

  }

  render() {

    if (!this.props.applicants && !this.props.applicants.new_applicant_data) {
      return null;
    }

    return (

      <React.Fragment>

        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Applicant Summary" key="1">
            <ApplicantSummary
              client_id={this.props.client_id}
            />
          </TabPane>
          <TabPane tab="Contact" key="2">
            <ApplicantContact
              client_id={this.props.client_id}
            />
          </TabPane>
          <TabPane tab="Address" key="3">
            <ApplicantAddress
              client_id={this.props.client_id}
            />
          </TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    applicants: state.applicants,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const ApplicantNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(ApplicantNewPageComponent)));

export default ApplicantNewPageComponentContainer;
