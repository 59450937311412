import React, { useState } from 'react';
import { Button, Col, Input, Row, Typography } from 'antd';
import { connect } from 'react-redux';
import CountrySelectComponent from '../../../common/CountrySelectComponent';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const { Search } = Input;

function DashboardPageComponent({ countries }) {
  const history = useHistory();
  const [searchParams, setSearchParams] = useState({
    userinput_country: 'Australia',
    userinput_region: '',
    userinput_keywords: '',
  });

  const countryName = (countryId) =>
    countries.length
      ? countries.find((item) => item.id === countryId)?.full_name
      : '';
  const getCountryId = (name) =>
    countries.length
      ? countries.find((item) => item.full_name === name)?.id
      : '';

  const handleChange = (event) => {
    const { id, value } = event.target;
    setSearchParams((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleChangeCountry = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      userinput_country: countryName(value),
    }));
  };

  const onSearch = () => {
    history.push(`/jobs?country=${searchParams.userinput_country}&region=${searchParams.userinput_region}&what=${searchParams.userinput_keywords}`);
  };

  return (
    <React.Fragment>
      <div>
        <Title level={4}>Search Jobs</Title>
        <Row justify="start" span={24} style={{ marginBottom: 80 }} gutter={[20]}>
          <Col xs={24} md={5}>
            <Text
              style={{
                padding: 5,
                display: 'block',
              }}
            >
              What
            </Text>
            <Search
              id="userinput_keywords"
              placeholder="Enter keywords"
              allowClear
              onChange={handleChange}
              onSearch={onSearch}
              value={searchParams.userinput_keywords}
            />
          </Col>

          <Col xs={24} md={4}>
            <Text
              style={{
                padding: 5,
                display: 'block',
              }}
            >
              Region
            </Text>
            <Search
              id="userinput_region"
              placeholder="Enter Region"
              allowClear
              value={searchParams.userinput_region}
              onChange={handleChange}
              onSearch={onSearch}
            />
          </Col>
          <Col xs={24} md={7}>
            <Text
              style={{
                padding: 5,
                display: 'block',
              }}
            >
              Country
            </Text>
            <Row style={{ flex: 1 }}>
              <CountrySelectComponent
                id="street_address_country"
                style={{ width: '100%' }}
                value={getCountryId(searchParams.userinput_country)}
                handleChange={handleChangeCountry}
              />
              <Button
                type="primary"
                style={{ marginLeft: 5 }}
                icon={<SearchOutlined />}
                onClick={onSearch}
              >
                Search
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    countries: state.countries,
  };
};


export default connect(mapStateToProps)(DashboardPageComponent);
