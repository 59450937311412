import { subscriptionsType } from '../type';

const initialState = [];

export default function subscriptionsReducer(state = initialState, action) {
  switch (action.type) {
    case subscriptionsType.GET_SUBSCRIPTIONS_SUCCESS:
      return action.data;

    default:
      return state;
  }
}
