import { applicantSubscriptionAPI } from '../../services/payment';
import { subscriptionsType } from '../type';

const getSubscriptions = () => {
  return (dispatch) => {
    return applicantSubscriptionAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: subscriptionsType.GET_SUBSCRIPTIONS_SUCCESS,
            data: data.data
          });
        }
      })
      .catch(error => {
        console.log(error);
	  })
  }
};

export {
  getSubscriptions
}
