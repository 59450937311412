
import React from 'react';
import { connect } from 'react-redux';
import { PageHeader, Card } from 'antd';
import UserProfilePageComponent from '../../components/pages/dashboard/user_profile/UserProfilePageComponent';

function UserProfilePage() {
  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title="User Profile"
      />
      <Card
        style={{ minHeight: 360 }}
        title="View and update your User Details"
      >
        <div style={{ paddingTop: 24 }}>
          <UserProfilePageComponent />
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const UserProfilePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfilePage);

export default UserProfilePageContainer;
