import React from 'react';

const SubHeading = (props) => {
  return (
    <div style={{ fontSize: 13 }}>
      {props.children}
    </div>
  );
};

export default React.memo(SubHeading);