import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CompanyListView from './CompanyListView';
import { getSavedCompaniesList } from '../../../../redux/actions/companies';

const CompanySavedListPageComponent = ({ savedCompanies, getSavedCompaniesHandler }) => {
  useEffect(() => {
    getSavedCompaniesHandler();
  }, []);

  return (
    <div>
      <CompanyListView companies={savedCompanies} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    savedCompanies: state.companies.savedCompanies,
  }
};

const mapDispatchToProps = ({
  getSavedCompaniesHandler: getSavedCompaniesList,
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySavedListPageComponent);
