import { Card, PageHeader } from 'antd';
import React from 'react';
import ApplicationViewPageComponent from '../../../components/pages/dashboard/applications/ApplicationViewPageComponent';

const ApplicationViewPage = (props) => {
  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Application View'
      />
      <Card
        style={{ minHeight: 360 }}
        title=''
      >
        <div style={{ paddingTop: 24 }}>
          <ApplicationViewPageComponent id={props.match.params.id} />
        </div>
      </Card>
    </div>
  );
};

export default ApplicationViewPage;
