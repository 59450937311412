import { jobProfilesType } from '../type';
import * as R from 'ramda';

const initialState = {
  jobProfiles: {},
  jobProfilesList: []
};

export default function jobProfileReducer(state = initialState, action) {
  switch (action.type) {
    case jobProfilesType.CREATE_JOB_PROFILES:
    case jobProfilesType.GET_JOB_PROFILES:
    case jobProfilesType.UPDATE_JOB_PROFILES:
      return R.assoc('jobProfiles', action.jobProfiles, state);
    case jobProfilesType.GET_JOB_PROFILES_LIST:
      return R.assoc('jobProfilesList', action.jobProfilesList, state);
    default:
      return state;
  }
}
