import React, { useState, useEffect } from 'react';

import { Tabs, Spin } from 'antd';
import withStyles from 'react-jss';
import queryString from 'query-string';
import { useLocation, useHistory, withRouter } from 'react-router-dom';

import MySubscription from './subscription_view/MySubscription';
import CreateSubscription from './subscription_view/CreateSubscription';
import { isValidSubscriptionAPI } from '../../../../services/payment';

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right',
  },
};

const SubscriptionViewPageComponent = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [activeTab, setState] = useState('');

  useEffect(() => {
    const { tab: activeTab } = queryString.parse(location.search);
    setState(activeTab);
  }, [location.search]);

  const onTabClick = (e) => {
    if (e === 'create_subscription') {
      setLoading(true);
      isValidSubscriptionAPI()
        .then((data) => {
          setLoading(false);
          if (data.data) {
            history.push('/settings/subscription/1?tab=my_subscription');
          } else {
            history.push(`/settings/subscription/${1}?tab=${e}`);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      history.push(`/settings/subscription/${1}?tab=${e}`);
    }
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Tabs
          activeKey={activeTab}
          onTabClick={onTabClick}
          defaultActiveKey="my_subscription"
          onChange={callback}
        >
          <TabPane tab="My Subscription" key="my_subscription">
            <MySubscription tab={activeTab} />
          </TabPane>
          <TabPane tab="Create Subscription" key="create_subscription">
            <CreateSubscription />
          </TabPane>
        </Tabs>
      </Spin>
    </React.Fragment>
  );
};

export default withRouter(
  withStyles(styles)(SubscriptionViewPageComponent),
);
