import {
  subscriptionPricesAPI
} from '../../services/payment';
import { paymentType } from '../type';

const getSubscriptionPrices = () => {
  return (dispatch) => {
    return subscriptionPricesAPI()
      .then((data) => {
        return dispatch({
          type: paymentType.GET_SUBSCRIPTION_PRICES,
          subscriptionPrices: data.data
        });
      })
      .catch(error => {
	  })
  }
};

export {
  getSubscriptionPrices
}