import { companiesType } from '../type';
import { getCompaniesAPI, getCompanyAPI, getSavedCompaniesAPI } from '../../services/companies';

/* Get all companies  */
const getCompanies = (params) => {
  return (dispatch) => {
    return getCompaniesAPI(params)
      .then((data) => {
        if (data) {
          dispatch({
            type: companiesType.GET_COMPANIES,
            companies: data.data
          });
        } // TODO Error Action Calling

      })
      .catch(error => {
        // TODO Error Action Calling
      })
  }
};

/* Get one Company by Id  */
const getCompany = (id) => {
  return (dispatch) => {
    return getCompanyAPI(id)
      .then((data) => {
        if (data) {
          return data.data;
        } // TODO Error Action Calling

      })
      .catch(error => {
        // TODO Error Action Calling
      });
  }
};

const getSavedCompaniesList = () => {
  return (dispatch) => getSavedCompaniesAPI()
    .then(res => dispatch({
      type: companiesType.GET_SAVED_COMPANIES,
      savedCompanies: res.data.map(item => item.workplaceProfile),
      savedCompaniesCount: res.data.length,
    }))
    .catch(error => {
      // TODO Error Action Calling
    });
};

export {
  getCompanies,
  getCompany,
  getSavedCompaniesList,
}
