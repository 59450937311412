import { EyeOutlined } from '@ant-design/icons';
import React, {
  useEffect, useState
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Spin, Button, Col } from 'antd';
import { NavLink } from 'react-router-dom';

import {
  getJobProfilesList
} from '../../../../redux/actions/jobProfiles';

import '../../../../style/table.scss'

const columns = [
  {
    title: 'Public Profile Name',
    dataIndex: 'public_fileBy',
    width: '20'
  },
  {
    title: 'Private Profile Name',
    dataIndex: 'file_by',
    width: '20'
  },
  {
    title: 'Visible to HeadHunters',
    dataIndex: 'visible_applications',
    width: '20'
  },
  {
    title: 'Actions',
    key: 'action',
    render: (text, record) => (
      <span>
        <NavLink to={`/profile/job-profiles/view/${record.id}`} >
          <strong>
            <EyeOutlined />
          </strong>
        </NavLink>
      </span>
    ),
  },
];
  
const JobProfileListViewComponent = (props) => {
  const { noAction } = props;
  const [loading, setLoading] = useState(false);
  const jobProfilesList = useSelector(state => state.jobProfiles.jobProfilesList);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleGetJobProfiles = async () => {
      setLoading(true);
      await dispatch(getJobProfilesList());
      setLoading(false);
    };
    handleGetJobProfiles();
  }, [setLoading, dispatch]);

const data = jobProfilesList.map((jobProfilesList) => ({
  ...jobProfilesList,
  visible_applications: jobProfilesList.visible_applications ? <img src={require('../../../../assets/img/GreenTick_20px.png')} /> : <img src={require('../../../../assets/img/RedCrossIcon_20px.png')} />
}));

  return (
    <Spin spinning={loading}>
      <Table
        className="custom-table"
        columns={columns}
        dataSource={data || []}
      />
      {
        !noAction && (
          <Col style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <Button type='primary' style={{ marginTop: 30 }}>
              <NavLink to='/profile/job-profiles/new'>Create New</NavLink>
            </Button>
          </Col>
        )
      }
    </Spin>
  );
}

export default React.memo(JobProfileListViewComponent);
