import { combineReducers } from 'redux';

import app_state from './app_state';
import language from './language';
import applicant from './applicant';
import applications from './applications';
import auth from './auth';
import countries from './countries';
import jobProfiles from './jobProfiles';
import jobClassifications from './job_classifications';
import jobs from './jobs';
import companies from './companies';
import payment from './payment';
import documents from './documents';
import subscriptions from './subscriptions';

const rootReducer = combineReducers({
  app_state,
  language,
  applicant,
  auth,
  countries,
  jobProfiles,
  jobClassifications,
  jobs,
  companies,
  payment,
  applications,
  documents,
  subscriptions,
});

export default rootReducer;
