import React from 'react';
import { Card } from 'antd';

import PageTitle from '../../../components/page-title/page-title';
import DocumentCreateComponent from '../../../components/pages/dashboard/MyDocument/DocumentCreateComponent';

const MyDocumentCreate = () => {
  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageTitle title='Create Document'>
        On this page, you can add documents such as CVs or applications covering letters. These can be selected to attach to applications you make
      </PageTitle>
      <Card
        style={{ minHeight: 360 }}
        title=''
      >
        <div style={{ paddingTop: 24 }}>
          <DocumentCreateComponent />
        </div>
      </Card>
    </div>
  );
}

export default React.memo(MyDocumentCreate);
