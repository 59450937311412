import React, { useState, useEffect } from 'react';
import {
  Input,
  Row,
  Col,
  Button,
  Typography,
  Modal,
  notification,
  Spin,
  Pagination,
} from 'antd';
import { connect } from 'react-redux';
import { SearchOutlined, SaveOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import withStyles from 'react-jss';
import listHOC from '../../../common/listHOC';
import { getList } from '../../../../redux/actions/jobs';
import { saveSearchJobAPI } from '../../../../services/jobs';
import { createLoggingAPI } from '../../../../services/logging';
import { searchAdzunaJobsAPI } from '../../../../services/externalApi';
import CountrySelectComponent from '../../../common/CountrySelectComponent';
import useDebounce from '../../../../hooks/useDebounce';

const { Text } = Typography;
const { Search } = Input;
const { confirm } = Modal;

const styles = {
  listView: {
    border: '1px solid #EBEDF0',
    marginBottom: 20,
    fontSize: 16,
  },
  pjsListView: {
    border: '1px solid #EBEDF0',
    marginBottom: 20,
    fontSize: 16,
  },
  listItem: {
    borderBottom: '1px solid #EBEDF0',
    padding: '15px 20px',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    marginBottom: 0,
    color: 'var(--global--color-brand)',
    fontWeight: 700,
    marginRight: 15,
  },
  subTitle: { fontWeight: 'bold' },
  wrapper: { marginBottom: 25 },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

function JobSearchComponent(props) {
  const {
    countries,
    jobClassifications,
    searchJobs,
    jobs,
    classes,
    user
  } = props;
  const [saveSearchName, setSaveSearchName] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [jobTableData, setJobTableData] = useState([]);
  const [adzunaJobTableData, setAdzunaJobTableData] = useState([]);
  const [adzunaJobTotalCount, setAdzunaJobTotalCount] = useState(0);
  const [adzunaPageSize, setAdzunaPageSize] = useState(10);
  const [adzunaCurrentPage, setAdzunaCurrentPage] = useState(1);
  const { location } = useHistory();
  const history = useHistory();
  const [pjsPage, setPjsPage] = useState(1);

  const [searchParams, setSearchParams] = useState({
    userinput_classification: [],
    userinput_country: 'Australia',
    userinput_region: '',
    userinput_keywords: '',
  });
  const debounceRegion = useDebounce(searchParams.userinput_region, 300);

  useEffect(() => {
    const params = queryString.parse(location.search);
    const classifications = params?.classification
      ? params.classification.split(',')
      : [];

    const classificationsArray = classifications
      .map((item) => {
        return jobClassifications?.find(
          (jobClassification) => jobClassification.id == item,
        )?.file_by;
      })
      .filter((item) => item);
    const searchParams = {
      userinput_classification: classificationsArray,
      userinput_country: params?.country || 'Australia',
      userinput_region: params?.region || '',
      userinput_keywords: params?.what || '',
    };
    setSearchParams(searchParams);
    handlerSearchJobs(searchParams);
    setJobTableData([]);
  }, []);

  useEffect(() => {
    const data = jobs.map((job, index) => ({
      ...job,
      work_option_part_time: job.work_option_part_time ? 'YES' : 'NO',
      work_option_full_time: job.work_option_full_time ? 'YES' : 'NO',
      platinum_tick_job: job.platinum_tick_job ? 'Yes' : 'No',
    }));
    setJobTableData(data);
  }, [jobs]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setSearchParams((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  useEffect(() => {
    handlerAdzunaSearchJobs(
      searchParams.userinput_keywords,
      searchParams.userinput_country,
      searchParams.userinput_region,
      adzunaCurrentPage,
      adzunaPageSize,
    );
  }, [location.search]);

  const handleChangeSearchName = (event) => {
    const { value } = event.target;
    setSaveSearchName(value);
  };

  const handleChangeCountry = (value) => {
    setSearchParams((prev) => ({
      ...prev,
      userinput_country: countryName(value),
    }));
    handlerSearchJobs({
      ...searchParams,
      userinput_country: countryName(value),
    });
  };
  const handlerSearchJobs = async (params) => {
    setLoading(true);
    await createLoggingAPI({
      user_applicant_id: user.id,
      log_type: 3,
      record_created: new Date(),
      memo: `Search ${params.userinput_keywords} in location ${params.userinput_region}, ${params.userinput_country}`
    });
    await searchJobs(params);
    await handlerAdzunaSearchJobs(
      params.userinput_keywords,
      params.userinput_country,
      params.userinput_region,
      adzunaCurrentPage,
      adzunaPageSize,
    );
    setPjsPage(1);
    setLoading(false);
  };
  const countryIOS = (countryName) =>
    countries.length
      ? countries.find((item) => item.full_name === countryName)?.iso_alpha_2
      : 'au';
  const countryName = (countryId) =>
    countries.length
      ? countries.find((item) => item.id === countryId)?.full_name
      : '';
  const getCountryId = (name) =>
    countries.length
      ? countries.find((item) => item.full_name === name)?.id
      : '';

  const showModal = () => {
    setVisible(true);
  };

  const handleClickRow = (row) => {
    history.push({
      pathname: `/jobs/view/${row.id[0] === 'E' ? 'ADZ' : 'PJS'}/${row.id}`,
      state: {
        record: row.id[0] === 'E' ? row : null,
        country: searchParams.userinput_country,
        region: searchParams.userinput_region,
      },
    });
  };

  useEffect(() => {
    history.push(`/jobs?country=${searchParams.userinput_country}&region=${searchParams.userinput_region}&what=${searchParams.userinput_keywords}`);
  }, [searchParams]);

  const handleOk = () => {
    setConfirmLoading(true);
    const saveParams = {
      nickname: saveSearchName,
      active: true,
      searchFilters: searchParams,
    };
    saveSearchJobAPI(saveParams)
      .then((data) => {
        setVisible(false);
        setConfirmLoading(false);
        setSaveSearchName('');
        openNotificationWithIcon(
          'success',
          'Save Search Job',
          'Success saving your job search!',
        );
      })
      .catch((error) => {
        setConfirmLoading(false);
        if (error.message === 'Already exist') {
          openNotificationWithIcon(
            'error',
            'Save Search Job',
            <div>
              Your nickname already exsit! <br></br>Please save different name.
            </div>,
          );
        } else {
          setVisible(false);
          openNotificationWithIcon(
            'error',
            'Save Search Job',
            'Failed saving your job search!',
          );
        }
      });
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const searchAdzunaJobs = async (page, pageSize) => {
    setAdzunaCurrentPage(page);
    setAdzunaPageSize(pageSize);
    setLoading(true);
    await handlerAdzunaSearchJobs(
      searchParams.userinput_keywords,
      searchParams.userinput_country,
      searchParams.userinput_region,
      page,
      pageSize,
    );
    setLoading(false);
  };

  const onSearch = () => {
    handlerSearchJobs(searchParams);
  };

  const handlerAdzunaSearchJobs = async (
    searchKey,
    country,
    region,
    page = 1,
    pageSize = 10,
  ) => {
    const params = queryString.parse(location.search);
    if (country !== params?.country) return;

    const country_ios = countryIOS(country)?.toLowerCase();
    try {
      const adzunaJobList = await searchAdzunaJobsAPI(
        searchKey,
        country_ios,
        page,
        region,
        pageSize,
      );
      setAdzunaJobTotalCount(adzunaJobList.count);
      const updateAdzunaJobList = adzunaJobList.results.map((job, index) => ({
        id: `E${job.id}`,
        createdAt: job.created,
        platinum_tick_job: 'NO',
        job_title: job.title,
        work_option_part_time: job.contract_time === 'part_time' ? 'YES' : 'NO',
        work_option_full_time: job.contract_time === 'full_time' ? 'YES' : 'NO',
        remote_work: '',
        location_region: job.location && job.location.display_name,
        location_country_id: getCountryId(searchParams.userinput_country),
        advertiser_reference: '',
        salary_low: job.salary_min,
        salary_high: job.salary_max,
        job_start: '',
        job_expire: '',
        title: job.title,
        description: job.description,
        redirect_url: job.redirect_url,
      }));
      setAdzunaJobTableData(updateAdzunaJobList);
    } catch (err) {
      console.error(err);
    }
  };

  const formatDescription = (str) => str.length > 255 ? `${str.slice(0, 255)}...` : str;

  return (
    <React.Fragment>
      <Row justify="start" span={24} style={{ marginBottom: 30 }} gutter={[20]}>
        <Col xs={24} md={5}>
          <Text
            style={{
              padding: 5,
              display: 'block',
            }}
          >
            What
          </Text>
          <Search
            id="userinput_keywords"
            placeholder="Enter keywords"
            allowClear
            onChange={handleChange}
            onSearch={onSearch}
            value={searchParams.userinput_keywords}
          />
        </Col>

        <Col xs={24} md={4}>
          <Text
            style={{
              padding: 5,
              display: 'block',
            }}
          >
            Region
          </Text>
          <Search
            id="userinput_region"
            placeholder="Enter Region"
            onSearch={onSearch}
            allowClear
            value={searchParams.userinput_region}
            onChange={handleChange}
          />
        </Col>
        <Col xs={24} md={7}>
          <Text
            style={{
              padding: 5,
              display: 'block',
            }}
          >
            Country
          </Text>
          <Row style={{ flex: 1 }}>
            <CountrySelectComponent
              id="street_address_country"
              style={{ width: '100%' }}
              value={getCountryId(searchParams.userinput_country)}
              handleChange={handleChangeCountry}
            />
            <Button
              type="primary"
              style={{ marginLeft: 5 }}
              icon={<SearchOutlined />}
              onClick={() => handlerSearchJobs(searchParams)}
            >
              Search
            </Button>
          </Row>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <div className={classes.wrapper}>
          <div className={classes.pjsListView}>
          {jobTableData.length > 0 || adzunaJobTableData.length > 0 ||
            jobTableData
              .slice((pjsPage - 1) * 2, pjsPage * 2)
              .map((item) => (
                <div key={item.id} className={classes.listItem} onClick={() => handleClickRow(item)}>
                  <div className={classes.header}>
                    <div className={classes.title} dangerouslySetInnerHTML={{ __html: item.job_title }} />
                    <img src={require('../../../../assets/img/brand/platinum_tick.png')} alt='' height={25} />
                  </div>
                  <div className={classes.subTitle}>{item?.job_classification?.file_by}</div>
                  <br />
                  <div>
                    <em>{item?.workplace_profiles?.workplace_name}</em>
                  </div>
                  <div>
                    {item?.location_region},{' '}
                    {countryName(item?.location_country_id)}
                  </div>
                  <br />
                  <div>{item?.short_description}</div>
                </div>
              ))
          }
          </div>
          {jobTableData.length < 1 && adzunaJobTableData.length < 1 &&
            <div>
              <em>{'No results matched these search criteria'}</em>
            </div>
          }
          {jobTableData.length > 0 && 
          <Pagination
            pageSize={2}
            className={classes.pagination}
            current={pjsPage}
            onChange={setPjsPage}
            total={jobTableData.length}
          />
          }
        </div>
        <div className={classes.wrapper}>
          <div className={classes.listView}>
            {adzunaJobTableData
              .map((item) => (
                <div key={item.id} className={classes.listItem} onClick={() => handleClickRow(item)}>
                  <div className={classes.title} dangerouslySetInnerHTML={{ __html: item.job_title }} />
                  <div>
                    {item?.location_region},{' '}
                    {countryName(item?.location_country_id)}
                  </div>
                  <br />
                  <div dangerouslySetInnerHTML={{ __html: formatDescription(item?.description)}} />
                </div>
              ))}
          </div>
          <Pagination
            className={classes.pagination}
            current={adzunaCurrentPage}
            onShowSizeChange={searchAdzunaJobs}
            onChange={searchAdzunaJobs}
            total={adzunaJobTotalCount}
            showSizeChanger={false}
            pageSize={15}
          />
        </div>
      </Spin>
      <Row justify="end" align="bottom">
        <Button
          type="primary"
          style={{
            marginLeft: 5,
            marginTop: 30,
          }}
          icon={<SaveOutlined />}
          disabled={
            !(
              searchParams.userinput_classification.length > 0 ||
              searchParams.userinput_country ||
              searchParams.userinput_region ||
              searchParams.userinput_keywords
            )
          }
          onClick={showModal}
        >
          Save Search
        </Button>
      </Row>
      <Modal
        title="Save Search"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Input Search Name</p>
        <Input
          placeholder="Enter Search Name"
          allowClear
          value={saveSearchName}
          onChange={handleChangeSearchName}
        />
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.currentUser,
    countries: state.countries,
    jobClassifications: state.jobClassifications,
    jobs: state.jobs.jobs,
    subscriptions: state.subscriptions,
  };
};

const mapDispatchToProps = { searchJobs: getList };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(listHOC(JobSearchComponent)));
