import React from 'react';
import { PageHeader } from 'antd';
import PropTypes from 'prop-types';

import SubHeading from '../sub-heading/sub-heading';

const PageTitle = (props) => {
  const { children, title } = props;
  return (
    <div style={{
      paddingLeft: 0,
      borderBottom: '1px solid rgb(235, 237, 240)',
      marginBottom: 16,
      paddingBottom: 16
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          paddingBottom: 4
        }}
        title={title}
      />
      <SubHeading>
        {children}
      </SubHeading>
    </div>
  );
};

PageTitle.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired
}

export default React.memo(PageTitle);
