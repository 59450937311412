import React from 'react';

import {
  Card,
  PageHeader,
} from 'antd';

import DocumentViewComponent from '../../../components/pages/dashboard/MyDocument/DocumentViewComponent';

const MyDocumentView = () => {
  
  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='My Profiles'
      />
      <Card
        style={{ minHeight: 360 }}
        title=''
      >
        <div style={{ paddingTop: 24 }}>
          <DocumentViewComponent />
        </div>
      </Card>
    </div>

  );
}

export default MyDocumentView;
