import { applicantType } from '../type';

const initialState = {
}

export default function applicantReducer(state = initialState, action) {
	switch (action.type) {
		case applicantType.GET_APPLICANT:
			return {
				...state,
        ...action.applicant
			}

		case applicantType.UPDATE_APPLICANT:
			return {
				...state,
        ...action.applicant
			}

		default:
			return state;
	}
}