import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { Row, Col, Form, Button, notification } from 'antd';
import { getCompany, getSavedCompaniesList } from '../../../../redux/actions/companies';
import JobListPageComponent from '../../../../components/pages/dashboard/jobs/JobListPageComponent';
import { isSavedCompanyApi, saveCompanyAPI, unmarkSavedCompanyApi } from '../../../../services/companies';
import './index.scss';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

function CompanyDetailComponent(props) {
  const { getCompany, id, getSavedCompaniesHandler } = props;
  const [company, setCompany] = useState(null);
  const user = useSelector(state => state.auth.currentUser);
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    getCompany(id).then(data => {
      setCompany(data);
    });
  }, [getCompany, id]);

  useEffect(() => {
    fetchSavedCompany();
  }, [id, user]);

  const onSaveCompany = () => {
    saveCompanyAPI({
      workplaceProfileId: parseInt(id, 10),
      applicantId: user?.applicant_id
    })
      .then(res => {
        openNotificationWithIcon(
          'success',
          'Save Company',
          'Success saving your company!',
        );
        fetchSavedCompany();
        getSavedCompaniesHandler();
      })
      .catch(err => {
        if (err.message === 'Already exist') {
          openNotificationWithIcon(
            'error',
            'Save Company',
            'This company is already saved!',
          );
        } else {
          openNotificationWithIcon(
            'error',
            'Save Company',
            'Failed saving your company!',
          );
        }
      })
  };

  const fetchSavedCompany = () => {
    isSavedCompanyApi(id, user?.applicant_id)
      .then(res => {
        setIsSaved(res.data);
      })
      .catch(err => {
        setIsSaved(false);
      });
  };

  const unmarkSavedCompany = () => {
    unmarkSavedCompanyApi({
      workplaceProfileId: parseInt(id, 10),
      applicantId: user?.applicant_id
    })
      .then(res => {
        openNotificationWithIcon(
          'success',
          'Unmark Saved Company',
          'Success unmark saved company!',
        );
        fetchSavedCompany();
        getSavedCompaniesHandler();
      })
      .catch(err => {
        openNotificationWithIcon(
          'error',
          'Unmark Saved Company',
          'Failed unmark saved company!',
        );
      });
  };

  return (
    <React.Fragment>
      <Form layout="vertical">
        <Row gutter={[96, 24]}>
          <Col xs={12}>
            {
              company?.profileHtml ? (
                <div dangerouslySetInnerHTML={{ __html: company?.profileHtml }} className='htmlProfile'/>
              ) : (
                <div>No Profile Html</div>
              )
            }
            {
              isSaved ? (
                <Button
                  type="danger"
                  onClick={unmarkSavedCompany}
                >
                  Delete Saved Company
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={onSaveCompany}
                >
                  Save Company
                </Button>
              )
            }
          </Col>
          <Col xs={12}>
            <h3>Current jobs with this advertiser</h3>
            <JobListPageComponent workplace_profile_id={id} />
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
  }
};

const mapDispatchToProps = ({ getCompany, getSavedCompaniesHandler: getSavedCompaniesList });

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailComponent);
