import DashboardPage from '../views/dashboard/DashboardPage';
import UserProfilePage from '../views/dashboard/UserProfilePage';
import ApplicantNewPage from '../views/dashboard/applicants/ApplicantNewPage';
import ApplicantListPage from '../views/dashboard/applicants/ApplicantListPage';
import ApplicantViewPage from '../views/dashboard/applicants/ApplicantViewPage';
import JobListPage from '../views/dashboard/jobs/JobListPage';
import JobViewPage from '../views/dashboard/jobs/JobViewPage';
import PJSJobViewPage from '../views/dashboard/jobs/PJSJobViewPage';
import JobSavedListPage from '../views/dashboard/jobs/JobSavedListPage';
import JobSavedSearchesPage from '../views/dashboard/jobs/JobSavedSearchesPage';
import CompanySearchPage from '../views/dashboard/companies/CompanySearchPage';
import CompanyDetailPage from '../views/dashboard/companies/CompanyDetailPage';
import CompanySavedListPage from '../views/dashboard/companies/CompanySavedListPage';
import ApplicationArchivePage from '../views/dashboard/applications/ApplicationArchivePage';
import FAQPage from '../views/dashboard/FAQPage';
// import LegalPage from '../views/dashboard/LegalPage';
import LegalTermsConditionsPage from '../views/dashboard/LegalTermsConditionsPage';
import LegalPrivacyPolicyPage from '../views/dashboard/LegalPrivacyPolicyPage';
import ApplicationListPage from '../views/dashboard/applications/ApplicationListPage';
import ApplicationNewPage from '../views/dashboard/applications/ApplicationNewPage';
import ApplicationViewPage from '../views/dashboard/applications/ApplicationViewPage';
import JobProfileView from '../views/dashboard/job-profile/JobProfileView';
import JobProfileListView from '../views/dashboard/job-profile/JobProfileListView';
import SubscriptionViewPage from '../views/dashboard/subscriptions/SubscriptionViewPage';

import MydocumentsList from '../views/dashboard/my-document/MydocumentsList';
import MyDocumentView from '../views/dashboard/my-document/MyDocumentView';
import MyDocumentCreate from '../views/dashboard/my-document/MyDocumentCreate'
const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    // name_es: languageObj_es_json["icon.dashboard"],
    // name_de: languageObj_de_json["icon.dashboard"],
    // name_zh: languageObj_zh_json["icon.dashboard"],
    // name_fr: languageObj_fr_json["icon.dashboard"],
    // name_it: languageObj_it_json["icon.dashboard"],
    // name_pt: languageObj_pt_json["icon.dashboard"],
    // icon: Accessibility,
    // invisible: true,
    component: DashboardPage
  },
  {
    path: '/profile/job-profiles/view/:id',
    name: 'My Job Profiles',
    component: JobProfileView
  },
  {
    path: '/profile/job-profiles/new',
    name: 'New Job Profile',
    component: JobProfileView
  },
  {
    path: '/profile/job-profiles',
    name: 'My Job Profiles',
    component: JobProfileListView
  },
  {
    path: '/profile/document-list',
    name: 'My Document',
    component: MydocumentsList
  },
  {
    path: '/profile/document-create',
    name: 'Create Document',
    component: MyDocumentCreate
  },
  {
    path: '/profile/document-view/:id',
    name: 'View Document',
    component: MyDocumentView
  },
  {
    path: '/my-job-profiles',
    name: 'My Job Profile',
    component: JobProfileView
  },
  // {
  //   path: '/applicants/new',
  //   name: 'New Applicant',
  //   component: ApplicantNewPage
  // },
  // {
  //   path: '/applicants/list',
  //   name: 'Applicant List',
  //   component: ApplicantListPage
  // },
  // {
  //   path: '/applicants/view/:client_id',
  //   name: 'View Applicant',
  //   component: ApplicantViewPage
  // },
  {
    path: '/jobs/view/PJS/:job_id',
    name: 'Job View',
    component: PJSJobViewPage
  },
  {
    path: '/jobs/view/ADZ/:job_id',
    name: 'Job View',
    component: JobViewPage
  },
  {
    path: '/jobs/saved-list',
    name: 'Job Saved List',
    component: JobSavedListPage
  },
  {
    path: '/jobs/saved-searches',
    name: 'Saved Searches',
    component: JobSavedSearchesPage
  },
  {
    path: '/jobs',
    name: 'Job List',
    component: JobListPage
  },
  {
    path: '/companies',
    name: 'Company Search',
    component: CompanySearchPage,
    exact: true,
  },
  {
    path: '/companies/view/:company_id',
    name: 'Company Detail',
    component: CompanyDetailPage
  },
  {
    path: '/companies/saved-list',
    name: 'Company Saved List',
    component: CompanySavedListPage
  },
  {
    path: '/applications/list',
    name: 'Application List',
    component: ApplicationListPage
  },
  {
    path: '/applications/new',
    name: 'Application New',
    component: ApplicationNewPage
  },
  {
    path: '/applications/view/:id',
    name: 'Application View',
    component: ApplicationViewPage
  },
  {
    path: '/applications/saved-list',
    name: 'Application Archive List',
    component: ApplicationArchivePage
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: FAQPage
  },
  {
    path: '/legal/terms-conditions',
    name: 'Legal Terms Conditions',
    component: LegalTermsConditionsPage
  },
  {
    path: '/legal/privacy-policy',
    name: 'Legal Privacy Policy',
    component: LegalPrivacyPolicyPage
  },
  // {
  //   path: '/legal',
  //   name: 'Legal',
  //   component: LegalPage
  // },
  {
    path: '/settings/subscription',
    name: 'Subscription View',
    component: SubscriptionViewPage
  },
  {
    path: '/profile/contact',
    name: 'User Profile',
    component: UserProfilePage
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/dashboard',
    name: 'Dashboard'
  }
];

export default dashRoutes;
