import { getDocumentsListAPI } from '../../services/documents';
import { documentsType } from '../type';

const getDocuments = () => {
  return (dispatch) => {
    return getDocumentsListAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: documentsType.GET_DOCUMENTS,
            data: data.data
          });
        } else console.log(data.msg);
      })
      .catch(error => {
        console.log(error);
	  })
  }
};

export {
  getDocuments
}