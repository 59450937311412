import React from 'react';
import withStyles from 'react-jss'

import {
  Switch, Redirect, NavLink
} from 'react-router-dom';
import {
  Layout, Menu, Icon , Row, Col
} from 'antd';

import pagesRoutes from '../routes/pages.jsx';
import UnauthenticatedRoute from '../components/routes/UnauthenticatedRoute';
import PagesHeaderContainer from '../components/layout/pages/PagesHeader.jsx';

const {
  Header, Content, Footer, Sider
} = Layout;

const styles = {

  main_content: {
    margin: 0,
    padding: 0,
    minHeight: 360,
    '@media (min-width: 420px)': {
      margin: 16,
      padding: 24,
    }
  },
  footerText: {
    // fontSize: 12,
    '@media (max-width: 420px)': {
      fontSize: 10,
    }
  },
}

class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      isAuthenticated: false
    };
  }

  onCollapse = collapsed => {
    // console.log(collapsed);
    this.setState({collapsed});
  };

  switchRoutes() {
    return (
      <Switch>
        {pagesRoutes.map((prop, key) => {
          if (prop.collapse) {
            return null;
          }
          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          }
          return (
            <UnauthenticatedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              props={{
                isAuthenticated: this.props.isAuthenticated,
                userHasAuthenticated: this.props.userHasAuthenticated,
                set_current_user: this.props.set_current_user
              }}
            />
          );
        })}
      </Switch>
    );
  }

  generate_sidebar = () => {
    return (
      <Menu theme='light' defaultSelectedKeys={['1']} mode='inline' style={{height: '100%'}}>
        <Menu.Item key='1'>
          <NavLink to='/'>
            <Icon type='user' />
            <span>Login</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key='2'>
          <NavLink to='/pages/register'>
            <Icon type='user' />
            <span>Register</span>
          </NavLink>
        </Menu.Item>
      </Menu>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Layout style={{minHeight: '100vh'}}>
        <Sider 
          theme="light" 
          style={{minHeight: '100vh'}} 
          breakpoint="md"
          collapsedWidth="0"
          collapsible 
          collapsed={this.state.collapsed} 
          onCollapse={this.onCollapse}>
          <div className='logo' />
          <img
            src={require('../assets/img/brand/PlatinumJobSeeker_300px.png')}
            alt=''
            target='_blank'
            style={{
              width: '100%',
              paddingTop: 16,
              marginBottom: 16,
              maxHeight: 64,
              maxWidth: 200
            }}
          />
          {this.generate_sidebar()}
        </Sider>
        <Layout>
          <Header style={{
            background: '#fff',
            padding: 0
          }}>
            <PagesHeaderContainer
              isAuthenticated={this.props.isAuthenticated}
              userHasAuthenticated={this.props.userHasAuthenticated}
              handleLogout={this.props.handleLogout}
            />
          </Header>
          <Layout>
            <Content>
              <div className={classes.main_content}>
                <Switch>
                  {pagesRoutes.map((prop, key) => {
                    if (prop.collapse) {
                      return null;
                    }
                    if (prop.redirect) {
                      return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                    }
                    return (
                      <UnauthenticatedRoute
                        path={prop.path}
                        component={prop.component}
                        key={key}
                        props={{
                          isAuthenticated: this.props.isAuthenticated,
                          userHasAuthenticated: this.props.userHasAuthenticated,
                          set_current_user: this.props.set_current_user
                        }}
                      />
                    );
                  })}
                </Switch>
              </div>
            </Content>
          </Layout>
          <Footer style={{textAlign: 'left'}}>
            <span className={classes.footerText}>
            <img src={require('../assets/img/brand/TickWithBorder_20px.png')} alt="tick-border" />
            <strong>
              Platinumjobseeker<sup>TM</sup>
            </strong>{' '}
            Jobseeker Portal © 2020-2022
            </span>
            <br />
            <small>Version 1.0.0</small>
            <br/>
            <small style={{textAlign: 'left'}}>
              <a href={'https://pjs-documents-public.s3.amazonaws.com/PJS+website+Terms+and+Conditions+27042022.pdf'} target='_blank' rel='noopener noreferrer'>
          	    Terms & Conditions
              </a>{' '} | {' '}
              <a href={'https://pjs-documents-public.s3.amazonaws.com/PlatinumJobSeeker+Privacy+Policy+22022021.pdf'} target='_blank' rel='noopener noreferrer'>
              Privacy Policy
              </a>{' '}
              |{' '}
              <a
                href={'https://www.platinumjobseeker.com/faq'}
                target="_blank"
                rel="noopener noreferrer"
              >
                FAQs
              </a>
            </small>
            {' '}
            <Row>
              <Col xs={24} style={{textAlign: 'right'}}>
                <small >
                Created by{' '}
                  <a href='https://deliostech.com/' target='_blank' rel="noopener noreferrer">
                  Delios Technology Inc
                  </a>
                </small>
              </Col>
            </Row>
          </Footer>
        </Layout>
      </Layout>
    );
  }
}

export default withStyles(styles)(Pages);
