import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

/* Define the Advertiser Endpoint */
const getJobsAPI = (params) => {
  const path = '/applicant/v1/jobs';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const getJobsStatusList = () => {
  const path = '/applicant/v1/jobs/status-list';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};
const getJobAPI = (id) => {
  const path = `/applicant/v1/jobs/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const getSavedJobSearchesAPI = () => {
  const path = `/applicant/v1/jobs/saved-searches`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
}

const updateJobAPI = (id, params) => {
  const path = `/applicant/v1/jobs/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const createJobAPI = (params) => {
  const path = `/applicant/v1/jobs/create`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const saveSearchJobAPI = (params) => {
  const path = `/applicant/v1/jobs/save-search`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const updateSavedSearchActiveAPI = (params) => {
  const path = `/applicant/v1/jobs/update-active`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const saveJobAPI = (params) => {
  const path = '/applicant/v1/jobs/save';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const isSavedJobApi = (jobId, applicantId) => {
  const path = `/applicant/v1/jobs/${jobId}/is-saved?applicantId=${applicantId}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const unmarkSavedJobApi = (params) => {
  const path = '/applicant/v1/jobs/unmark';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const getSavedJobsAPI = () => {
  const path = '/applicant/v1/jobs/saved';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const removeSavedJobsAPI = () => {
  const path = '/applicant/v1/jobs/saved/clear';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.DELETE);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

export {
  getJobsAPI,
  getJobAPI,
  updateJobAPI,
  createJobAPI,
  getJobsStatusList,
  saveSearchJobAPI,
  getSavedJobSearchesAPI,
  updateSavedSearchActiveAPI,
  saveJobAPI,
  isSavedJobApi,
  unmarkSavedJobApi,
  getSavedJobsAPI,
  removeSavedJobsAPI,
};
