import { Spin, Typography, Button } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import withStyles from 'react-jss'
import { getApplications } from '../../../../redux/actions/applications';
import { getJobAPI } from '../../../../services/jobs';

const { Text } = Typography;

const styles = {
  title: {marginBottom: 10,},
  flex: {
    display: 'flex',
    marginBottom: 10,
  },
  address: {
    marginTop: 50,
    marginBottom: 40,
  },
  label: { minWidth: 120 },
  left: { width: 180 },
  content: {},
  button: { marginTop: 20 }
};

const JobViewComponent = ({ getApplications, classes, countries }) => {
  const [currentJob, setCurrentJob] = useState({});
  const [loading, setLoading] = useState(false);
  const { job_id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const initData = async () => {
      setLoading(true);
      const currentJob = await getJobAPI(job_id);
      await getApplications();
      setCurrentJob(currentJob.data);
      setLoading(false);
    };
    if (!isAdzunaJob(job_id)) {
      initData();
    }
  }, [job_id]);

  useEffect(() => {
    if (location.state && location.state.record) {
      setCurrentJob(location.state.record);
    }
  }, [location]);

  const isAdzunaJob = jobId => jobId && jobId[0] === 'E';

  const country = useMemo(() => {
    return countries?.find(
      (item) => item.id === currentJob.location_country_id,
    )?.full_name;
  }, [currentJob]);

  return (
    <React.Fragment>
      <Spin
        spinning={loading || !currentJob}
        tip="Loading...">

        <div className={classes.title} dangerouslySetInnerHTML={{ __html: currentJob.job_title }} />
        <div dangerouslySetInnerHTML={{ __html: currentJob.description }} />

        <div className={classes.address}>
          <div className={classes.flex}>
            <div className={classes.label}>Location:</div>
            <div dangerouslySetInnerHTML={{ __html: currentJob.location_region }} />
          </div>

          <div className={classes.flex}>
            <div className={classes.label}>Country:</div>
            <div dangerouslySetInnerHTML={{ __html: country }} />
          </div>
        </div>

        <div>
          <div className={classes.flex}>
            <div className={classes.left}>Full Time work available:</div>
            <Text>{currentJob.work_option_full_time ? 'Yes' : 'No'}</Text>
          </div>
          <div className={classes.flex}>
            <div className={classes.left}>Part Time work available:</div>
            <Text>{currentJob.work_option_part_time ? 'Yes' : 'No'}</Text>
          </div>
          <div className={classes.flex}>
            <div className={classes.left}>Casual work available:</div>
            <Text>{currentJob.work_option_casual ? 'Yes' : 'No'}</Text>
          </div>
          <div className={classes.flex}>
            <div className={classes.left}>Salary Range:</div>
            <div>{currentJob.salary_low}-{currentJob.salary_high}</div>
          </div>
        </div>

        <Button href={currentJob.redirect_url} target='_blank' type="primary" className={classes.button}>Apply Now</Button>
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    advertiser: state.advertiser,
    currentUser: state.auth.currentUser,
    countries: state.countries,
    jobClassifications: state.jobClassifications,
    applications: state.applications,
  }
};

const mapDispatchToProps = ({getApplications: getApplications});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(JobViewComponent));
