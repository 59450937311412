
import React, { useState } from 'react';
import withStyles from "react-jss";

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';
import { Row, Col, Input, Form, Card, Button, message, Modal, Checkbox, notification } from 'antd';

import { signIn } from '../../../redux/actions/auth';
import { createLoggingAPI, updateApplicantLoggingRecordsAPI } from '../../../services/logging';
import { resetPasswordAPI } from '../../../services/auth';

const styles = {
  root: {
    padding: 0,
    paddingTop: 24,
    background: "#fff",
    minHeight: 360,
    "@media (min-width: 800px)": {
      padding: 0,
      paddingTop: 24,
    },
    "@media (min-width: 1000px)": {
      padding: 0,
      paddingTop: 24,
    },
    "@media (min-width: 1200px)": {
      padding: 24,
    },
  },
  main_card: {
    "@media (min-width: 1400px)": {
      width: "80%",
    },
    "@media (min-width: 1600px)": {
      width: "70%",
    },
  },
};

function LoginPageComponent(props) {
  const { signInHandler, history, intl, classes } = props;
  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: '',
    stayToken: false,
  });
  const [loginInfoStatus, setLoginInfoStatus] = useState({
    emailHelp: '',
    emailStatus: '',
    passwordHelp: '',
    passwordStatus: '',
    passwordResetUi: false,
    passwordResetInProcess: false,
    resetEmail: '',
    resetPasswordSendEmailHelp: '',
    resetEmailStatus: '',
    resetPasswordNewPassword: '',
    resetPasswordNewPasswordHelp: '',
    resetPasswordNewPasswordStatus: '',
    resetPasswordConfirmPassword: '',
    resetPasswordConfirmPasswordHelp: '',
    resetPasswordConfirmPasswordStatus: '',
  });

  const warning = (text) => {
    message.warning(text, 10);
  };

  const handleChangeStayToken = (e) => {
    const checked = e.target.checked;
    setLoginInfo(prev => ({
      ...prev,
      stayToken: checked
    }))
  }

  const validate_form = () => {
    let valid = true;

    if (loginInfo.email.length < 1) {
      setLoginInfoStatus(prev => ({
        ...prev,
        emailStatus: 'error',
        emailHelp: 'Email is required'
      }));
      valid = false;
    }

    if (loginInfo.password.length < 1) {
      setLoginInfoStatus(prev => ({
        ...prev,
        passwordStatus: 'error',
        passwordHelp: 'Password is required'
      }));
      valid = false;
    }

    if (loginInfo.password.length > 0 && loginInfo.email.length > 0) {
      setLoginInfoStatus(prev => ({
        ...prev,
        emailStatus: 'success',
        passwordStatus: 'success',
        emailHelp: ' ',
        passwordHelp: ' '
      }));
    }
    return valid;
  }

  const handleChange = event => {
    const { id, value } = event.target;
    setLoginInfo(prev => ({
      ...prev,
      [id]: value,
    }));

    if (id === 'email') {
      setLoginInfoStatus(prev => ({
        ...prev,
        emailStatus: 'success',
        emailHelp: ''
      }));
    }

    if (id === 'password') {
      setLoginInfoStatus(prev => ({
        ...prev,
        passwordStatus: 'success',
        passwordHelp: ' '
      }));
    }
  };

  const handleResetPassEmail = (event) => {
    const { value } = event.target;
    setLoginInfoStatus(prev => ({
      ...prev,
      resetEmail: value 
    }));
  }
  const handleForgotPassword = () => {
    setLoginInfoStatus(prev => ({
      ...prev,
      passwordResetUi: true
    }));
  }

  const resetPasswordHandle = () => {
    resetPasswordAPI({ email: loginInfoStatus.resetEmail})
      .then(() => {
        openNotificationWithIcon('success', "Success", "Sent the reset email");
        resetPasswordHandleCancel();
      }).catch(error => {
        openNotificationWithIcon('warning', "Failed", error.message);
        resetPasswordHandleCancel();
      })
  }

  const openNotificationWithIcon = (type, header, message) => {
    notification[type]({
      message: header,
      description: message,
    });
  };

  const resetPasswordHandleCancel = () => {
    setLoginInfoStatus(prev => ({
      ...prev,
      passwordResetUi: false
    }))
  }

  const handleRegister = () => {
    history.push('/pages/register');
  }

  const handleSubmit = async event => {
    if (!validate_form()) return;
    localStorage.setItem('STATUS_REQUIRE_WELCOME', 'true');
    
    signInHandler({
      email: loginInfo.email.toLowerCase(),
      password: loginInfo.password,
      stayToken: loginInfo.stayToken
    }).then(async data => {
      if (data) {
        await createLoggingAPI({
          user_applicant_id: data.user.id,
          log_type: 2,
          record_created: new Date(),
          memo: `user login success for email ${loginInfo.email}`
        });
        await updateApplicantLoggingRecordsAPI(
          data.user.applicant_id, {
            lastLoginDatetime: new Date()
          }
        )
      } else {
        warning('Incorrect username or password');
        await createLoggingAPI({
          user_applicant_id: 'null',
          log_type: 2,
          record_created: new Date(),
          memo: `User name or password fail for email ${loginInfo.email}`
        })
      }
    }).catch(async error => {
      warning('Incorrect username or password');
      await createLoggingAPI({
        user_applicant_id: 'null',
        log_type: 2,
        record_created: new Date(),
        memo: `User name or password fail for email ${loginInfo.email}`
      })
    });
  }

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xs={24} md={12}>
            <Card 
              // title={<FormattedMessage id='login_page.form_title' />} 
              className={classes.main_card}
              // style={{ maxWidth: 600 }}
              > 
                <p>
                  Welcome to <strong>Platinum JobSeeker<sup>TM</sup></strong>.  <br/>
                  Enter your username and password to login now.
                </p>
              <Form
                style={{ marginTop: 20 }}
              >
                <Form.Item
                  hasFeedback
                  help={loginInfoStatus.emailHelp}
                  validateStatus={loginInfoStatus.emailStatus}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'login_page.email_label',
                      defaultMessage: `Email`
                    })}
                    id='email'
                    value={loginInfo.email}
                    onChange={handleChange}
                  />
                </Form.Item>
                <Form.Item
                  hasFeedback
                  help={loginInfoStatus.passwordHelp}
                  validateStatus={loginInfoStatus.passwordStatus}
                >
                  <Input.Password
                    placeholder={intl.formatMessage({
                      id: 'login_page.password_label',
                      defaultMessage: `Password`
                    })}
                    id='password'
                    value={loginInfo.password}
                    onChange={handleChange}
                  />

                </Form.Item>
                <p style={{ marginBottom: '20px' }}>
                  <Checkbox
                    checked={loginInfo.stayToken}
                    onChange={handleChangeStayToken}
                  >
                    Keep me logged in on this device
                    </Checkbox>
                </p>
                <Form.Item>
                  <Button
                    type='primary'
                    style={{ width: '100%' }}
                    onClick={handleSubmit}
                  >
                    <FormattedMessage id='login_page.login_button' />
                  </Button>
                </Form.Item>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={24} sm={24} md={12}>
                    <Form.Item>
                      <Button
                        style={{ width: '100%' }}
                        onClick={handleRegister}
                      >
                        <FormattedMessage id='login_page.register_button' />
                      </Button>
                    </Form.Item>
                  </Col>

                  <Col xs={24} sm={24} md={12}>
                    <Form.Item>

                      <Button
                        style={{ width: '100%' }}
                        onClick={handleForgotPassword}
                      >
                        <FormattedMessage id='login_page.forget_password' />
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        visible={loginInfoStatus.passwordResetUi}
        title='Reset Password'
        onOk={resetPasswordHandle}
        onCancel={resetPasswordHandleCancel}
        footer={[
          <Button key='back' onClick={resetPasswordHandleCancel}>
            Cancel
            </Button>,
          <Button key='submit' type='primary' loading={loginInfoStatus.passwordResetInProcess} onClick={resetPasswordHandle}>
            Send Reset Email
            </Button>,
        ]}
      >
        <Form
          layout="vertical"
        >
          <Form.Item
            label='Email'
            help={loginInfoStatus.resetPasswordSendEmailHelp}
            validateStatus={loginInfoStatus.resetEmailStatus}
            style={{ margin: 0}}
          >
            <Input
              placeholder='Enter you email address'
              id='resetEmail'
              value={loginInfoStatus.resetEmail}
              onChange={handleResetPassEmail}
            />
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  );
}

const mapDispatchToProps = ({
  signInHandler: signIn,
})

export default withStyles(styles)(injectIntl(withRouter(connect(null, mapDispatchToProps)(LoginPageComponent))));