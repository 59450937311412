import { documentsType } from '../type';

const initialState = [];

export default function documentsReducer(state = initialState, action) {
  switch (action.type) {
    case documentsType.GET_DOCUMENTS:
      return action.data;

    default:
      return state;
  }
}
