import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button, Col, Input, Row,
  Spin, Table, Typography, Tag,
} from 'antd';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import moment from 'moment';
import withStyles from 'react-jss';

import { getApplications } from '../../../../redux/actions/applications';
import listHOC from '../../../common/listHOC';
import { formatDate } from '../../../../utils';

const { Text } = Typography;

const getCountryName = (countryId, countries) => {
  if (countries.length > 0) {
    return countries.find(item => item.id === countryId)?.full_name;
  } return ''
};

const styles = {
  table: {
    '& .ant-table-header tr th': {
      color: '#fff',
      background: '#21b466',
      fontWeight: 800,
      '&:hover': { background: '#31c476 !important' },
      '&:hover .ant-dropdown-trigger': { background: '#31c476 !important' },
    },
    '& .ant-table-header .anticon': { color: '#fff' },
  },
};

function ApplicationListPageComponent(props) {
  const {
    countries, searchApplications, applications, getColumnSearchProps,
    currentUser, classes, noAction
  } = props;
  const [loading, setLoading] = useState(false);

  const { location } = useHistory();
  const [searchParams, setSearchParams] = useState({});

  const handlerSearchApplications = useCallback(async (params) => {
    const {
      job_id ,applicant_profile_id, application_date_time,
      viewed_by_advertiser_id, viewed_by_advertiser_datetime
    } = params;
    const searchParams = {};
    if (job_id) {
      searchParams.job_id = job_id;
    }
    if (applicant_profile_id) {
      searchParams.applicant_profile_id = applicant_profile_id;
    }
    if (application_date_time) {
      searchParams.application_date_time = application_date_time;
    }
    if (viewed_by_advertiser_id) {
      searchParams.viewed_by_advertiser_id = viewed_by_advertiser_id;
    }
    if (viewed_by_advertiser_datetime) {
      searchParams.viewed_by_advertiser_datetime = viewed_by_advertiser_datetime;
    }
    if (currentUser?.applicant_id) {
      searchParams.applicant_id = currentUser.applicant_id;
    }
    setLoading(true);
    await searchApplications(searchParams);
    setLoading(false);
  }, [currentUser.applicant_id, searchApplications]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    setSearchParams(params);
    handlerSearchApplications(params);
  }, [currentUser.applicant_id, handlerSearchApplications, location.search]);

  const handleChange = useCallback((event) => {
    const { id, value } = event.target;
    setSearchParams({
      ...searchParams,
      [id]: value
    });
  }, [searchParams]);

  const data = useMemo(() => applications.map((job) => ({
    ...job,
    location_country_id: getCountryName(job.location_country_id, countries),
    platinum_tick_job: job.platinum_tick_job ? 'Yes' : 'No',
    job_title: job?.job?.job_title,
    applicantProfileTitle: job?.applicantProfileId?.file_by,
    application_made: moment(job.application_date_time).format('YYYY-MM-DD'),
    viewed_by_advertiser: `${job?.advertiser?.file_by ? job?.advertiser?.file_by : ''} ${job?.viewed_by_advertiser_datetime ? moment(job.viewed_by_advertiser_datetime).format('YYYY-MM-DD') : ''}`,
    status: job?.trafficLight?.fileBy,
    statusColor: job?.trafficLight?.color,
  })),[applications, countries]);

  const tableColumns = [
    {
      title: 'Job Applications',
      // dataIndex: 'application_made',
      // width: 200,
      // sorter: (a, b) => a.application_made - b.application_made,
      // ...getColumnSearchProps('application_made', 'Application Made'),
      render: (text, record) => (
        <React.Fragment>
          Application made {formatDate(text)}
          <br/>
          {record.job_title}
          <br/>
          Advertiser viewed: {formatDate(record.viewed_by_advertiser)}
          <br/>
          {record.status ? `Status: ${record.status}` : ''}
          <br/>
          <NavLink to={`/applications/view/${record.id}`}>
            <strong>
              Click to View Details
            </strong>
          </NavLink>
        </React.Fragment>
      ),
      responsive: ["xs"]
    },
    {
      title: 'Application Made',
      dataIndex: 'application_made',
      // width: 200,
      sorter: (a, b) => a.application_made - b.application_made,
      // ...getColumnSearchProps('application_made', 'Application Made'),
      render: (text) => (
        formatDate(text)
      ),
      responsive: ["sm"]
    },
    {
      title: 'Job',
      dataIndex: 'job_title',
      // width: 150,
      sorter: (a, b) => a.job_title - b.job_title,
      ...getColumnSearchProps('job_title', 'Job Title'),
      responsive: ["sm"]
    },
    {
      title: 'My Profile',
      dataIndex: 'applicantProfileTitle',
      // width: 150,
      sorter: (a, b) => a.applicantProfileTitle - b.applicantProfileTitle,
      // ...getColumnSearchProps('applicantProfileTitle', 'Applicant Profile'),
      responsive: ["sm"]
    },
    {
      title: 'Advertiser Viewed',
      dataIndex: 'viewed_by_advertiser',
      width: 200,
      sorter: (a, b) => a.viewed_by_advertiser - b.viewed_by_advertiser,
      // ...getColumnSearchProps('viewed_by_advertiser', 'Viewed by Advertiser'),
      responsive: ["sm"],
      render: (text) => (
        formatDate(text)
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 100,
      sorter: (a, b) => a.status - b.status,
      // ...getColumnSearchProps('status', 'Status'),
      responsive: ["sm"],
      render: (text, record) => (
        <Tag
          color={`#${record.statusColor}`}
          style={{
            width: 60,
            textAlign: 'center'
          }}
        >
          {text}
        </Tag>)
    },
    {
      title: 'Action',
      width: 100,
      responsive: ["sm"],
      render: (text, record) => (
        <NavLink to={`/applications/view/${record.id}`}>
          <strong>
            <EyeOutlined />
          </strong>
        </NavLink>
      ),
    },
  ];

  console.log('language', navigator.language);
  return (
    <React.Fragment>
      {
        !noAction && (
          <Row span={24} style={{ marginBottom: 30 }} justify="end" >
            <Col>
              <Text style={{
                padding: 5,
                display: 'block'
              }} >Job Id</Text>
              <Row style={{ flex: 1 }} justify="end" >
                <Col xs={18} md={18}>
                  <Input
                    id='job_id'
                    placeholder="Job Id"
                    value={searchParams?.job_id ?? ''}
                    allowClear
                    onChange={handleChange}
                  />
                </Col>
                  <Col xs={6} md={6}>
                  <Button
                    type="primary"
                    style={{ marginLeft: 5 }}
                    icon={<SearchOutlined />}
                    onClick={() => handlerSearchApplications(searchParams)}>
                    Search
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      }
      <Spin spinning={loading}>
        <Table className={classes.table} columns={tableColumns} dataSource={data} rowKey="id" sticky />
      </Spin>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return {
    countries: state.countries,
    jobClassifications: state.jobClassifications,
    applications: state.applications,
    currentUser: state.auth.currentUser,
  }
};

const mapDispatchToProps = ({searchApplications: getApplications});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(listHOC(ApplicationListPageComponent)));
