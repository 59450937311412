import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const getCompaniesAPI = (params) => {
  const path = `/applicant/v1/workplace-profiles`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const getCompanyAPI = (id) => {
  const path = `/applicant/v1/workplace-profiles/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, {});
};

const saveCompanyAPI = (params) => {
  const path = '/applicant/v1/workplace-profiles/save';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const isSavedCompanyApi = (id, applicantId) => {
  const path = `/applicant/v1/workplace-profiles/${id}/is-saved?applicantId=${applicantId}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const unmarkSavedCompanyApi = (params) => {
  const path = '/applicant/v1/workplace-profiles/unmark';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const getSavedCompaniesAPI = () => {
  const path = '/applicant/v1/workplace-profiles/saved';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

export {
  getCompaniesAPI,
  getCompanyAPI,
  saveCompanyAPI,
  isSavedCompanyApi,
  unmarkSavedCompanyApi,
  getSavedCompaniesAPI,
};
