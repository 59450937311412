import React, { useEffect } from 'react';

import { connect } from 'react-redux';

import listHOC from '../../../common/listHOC';
import { getList } from '../../../../redux/actions/jobs';

import { appStateSelector } from '../../../../redux/selectors';
import JobListView from './JobListView';

const JobListPageComponent = ({
  getJobListHandler,
  jobs,
  workplace_profile_id,
}) => {
  useEffect(() => {
    getJobListHandler({ workplace_profile_id });
  }, [workplace_profile_id, getJobListHandler]);

  const jobsData = jobs?.jobs ? jobs.jobs : [];

  if (!jobsData.length) {
    return (
      <div>
        No Jobs
      </div>
    );
  }

  return (
    <React.Fragment>
      <JobListView jobs={jobsData}/>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    jobs: state.jobs,
    current_account_id: appStateSelector(state).current_account_id,
  };
};

const mapDispatchToProps = {
  getJobListHandler: getList,
  fetchCb: getList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(listHOC(JobListPageComponent));
