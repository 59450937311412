import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withStyles from 'react-jss';
import { useHistory } from 'react-router-dom';
import { Row, Col, Card, Button, Typography, Spin, Modal, Space } from 'antd';
import StripeCheckout from 'react-stripe-checkout';

import {
  checkoutSubscriptionAPI,
  isValidSubscriptionAPI,
} from '../../../../../services/payment';
import { getSubscriptionPrices } from '../../../../../redux/actions/payment';
import { getSubscriptions } from '../../../../../redux/actions/subscriptions';
import { validateToken } from '../../../../../redux/actions/auth';

const STRIPE_ID = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const { Title, Text } = Typography;

const styles = (theme) => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
});

const type = [
  {
    name: 'PJS BASIC 30',
    priceDetail1: 'AUD 2 for 30 consecutive days.',
    priceDetail2: '',
    content: 'All features except video upload.',
    id: 0,
  },
  {
    name: 'PJS BASIC 90',
    priceDetail1: 'AUD 5 for 90 consecutive days.',
    priceDetail2: 'about 1.66 AUD per month (save approx. 16.67%)',
    content: 'All features except video upload.',
    save: 'Save 16.67%',
    id: 1,
  },
  {
    name: 'PJS BASIC 180',
    priceDetail1: 'AUD 8 for 180 consecutive days.',
    priceDetail2: 'about 1.33 AUD per month (save approx. 33%)',
    content: 'All features except video upload.',
    save: 'Save 33%',
    id: 2,
  },
  {
    name: 'PJS PLUS 30',
    priceDetail1: 'AUD 5 for 30 consecutive days.',
    priceDetail2: 'All features except video upload.',
    content:
      'All features including video upload and Highlighted profile and priority head hunter search results.',
    id: 3,
  },
  {
    name: 'PJS PLUS 90',
    priceDetail1: 'AUD 13 for 90 consecutive days.',
    priceDetail2: 'about 4.33 AUD per month (save approx. 16.67%)',
    content:
      'All features including video upload and Highlighted profile and priority head hunter search results.',
      save: 'Save 16.67%',
    id: 4,
  },
  {
    name: 'PJS PLUS 180',
    priceDetail1: 'AUD 25 for 180 consecutive days.',
    priceDetail2: 'about 4.16 AUD per month (save approx. 33%)',
    content:
      'All features including video upload and Highlighted profile and priority head hunter search results.',
      save: 'Save 33%',
    id: 5,
  },
];

function CreateSubscription(props) {
  const history = useHistory();
  const { user, subscriptionPrices = {}, getSubscriptionPricesHandler, validateTokenHandler, getSubscriptionsHandler } = props;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    isValidSubscriptionAPI()
      .then((data) => {
        setLoading(false);
        if (data.data) {
          history.push('/settings/subscription/1?tab=my_subscription');
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getSubscriptionPricesHandler();
  }, [getSubscriptionPricesHandler]);

  const gridStyle = {width: '100%',};

  const handleToken = (index) => (token) => {
    setLoading(true);
    const params = {
      source: token.id,
      planId: subscriptionPrices[index].stripe_id,
      applicant_id: user.id,
      subscriptionProductId: subscriptionPrices[index].subscription_products.id,
      priceId: subscriptionPrices[index].id,
    };
    checkoutSubscriptionAPI(params)
      .then((data) => {
        successModal();
        getSubscriptionsHandler()
          .then(() => {
            setLoading(false);
            history.push('/settings/subscription/1?tab=my_subscription');
          })
          .catch(()=> {
            setLoading(false);
          })
        validateTokenHandler();
      })
      .catch((error) => {
        failedModal();
        setLoading(false);
      });
  };

  const successModal = () => {
    Modal.success({
      title: 'Subscription',
      content: (
        <Title
          level={3}
          style={{
            marginLeft: -38,
            marginTop: 30,
            textAlign: 'center',
          }}
        >
          Thank you!
        </Title>
      ),
      onOk() {
        history.push(`/settings/subscription/${1}?tab=my_subscription`);
      },
    });
  };
  const failedModal = () => {
    Modal.error({
      title: 'Subscription',
      content: (
        <Title
          level={3}
          style={{
            marginLeft: -38,
            marginTop: 30,
            textAlign: 'center',
          }}
        >
          Sorry, subscription failed!
        </Title>
      ),
      onOk() {},
    });
  };

  if (loading || !subscriptionPrices.length || !StripeCheckout) {
    return <Spin />;
  }

  console.log('subscriptionPrices', subscriptionPrices);
  return (
    <React.Fragment>
      <Spin spinning={loading || !subscriptionPrices.length || !StripeCheckout}>
        <Title level={4} style={{ marginBottom: 40 }}>
          Create Subscription
        </Title>
        <code>TEST CARD NUMBER: 4000000360000006</code> - use any future expiry date and any 3 digit ccv
        <Row gutter={[50, 50]} justify="center">
          {type.map((item) => (
            <Col xs={8} key={item.id}>
              <Card
                title={item.name}
                style={gridStyle}
                actions={[
                  <React.Fragment>
                    <StripeCheckout
                      stripeKey={STRIPE_ID}
                      token={handleToken(item.id)}
                      amount={
                        subscriptionPrices[item.id]?.price *
                        subscriptionPrices[item.id]?.month_count
                      }
                      description={`${
                        subscriptionPrices[item.id]?.price / 100
                      } AUD / month`}
                      currency="AUD"
                    >
                      <Button style={{ marginLeft: 20 }} type="primary">
                        Subscribe Now
                      </Button>
                    </StripeCheckout>
                  </React.Fragment>,
                ]}
              >
                <Space size={10} direction="vertical">
                  <Text>
                    {item.priceDetail1}<br/>
                    {item.priceDetail2}
                  </Text>
                  <Text>{item.content}</Text>
                  <Text>We will notify you before expiry</Text>
                </Space>
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    subscriptionPrices: state.payment.subscriptionPrices,
    app_state: state.app_state,
    user: state.auth.currentUser,
  };
};

const mapDispatchToProps = {
  getSubscriptionPricesHandler: getSubscriptionPrices,
  validateTokenHandler: validateToken,
  getSubscriptionsHandler: getSubscriptions
};

const CreateSubscriptionContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateSubscription);

export default withStyles(styles, { injectTheme: true })(
  CreateSubscriptionContainer,
);
