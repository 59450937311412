import React, { useState, useEffect, useMemo } from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
import { Switch, Redirect, NavLink } from 'react-router-dom';
import { Alert, Layout, Menu, Icon, Row, Col, Space, Tooltip, Steps } from 'antd';

import dashboardRoutes from '../routes/dashboard.jsx';
import AuthenticatedRoute from '../components/routes/AuthenticatedRoute';
import DashboardHeaderContainer from '../components/layout/dashboard/DashboardHeader.jsx';
import {
  DashboardOutlined,
  BankOutlined,
  SolutionOutlined,
  ContainerOutlined,
  MailOutlined,
  QuestionOutlined,
  SettingOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import Welcome from '../components/welcome/welcome';

import '../style/sidebar.scss';

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
const { Step } = Steps;

const styles = {

  main_content: {
    margin: 0,
    padding: 0,
    minHeight: 360,
    background: '#fff',
    '@media (min-width: 420px)': {
      margin: 16,
      padding: 24,
    }
  },
  alert_text: {
    '@media (max-width: 420px)': {
      fontSize: 10,
      lineHeight: 0.6
    }
  },
  dashboard_steps: {
    '@media (max-width: 420px)': {
      display: 'none'
    }
  },
  footerText: {
    // fontSize: 12,
    '@media (max-width: 420px)': {
      fontSize: 10,
    }
  },
}

const Dashboard = ({
  isAuthenticated,
  current_user,
  reload_user,
  userHasAuthenticated,
  handleLogout,
  savedJobsCount,
  savedCompaniesCount,
  user,
  classes
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [hideWelcome, setHideWelcome] = useState(localStorage.getItem('STATUS_REQUIRE_WELCOME') !== "true");

  useEffect(() => {
    if (!hideWelcome) {
      const timer = setTimeout(() => {
        setHideWelcome(true);
      }, 4 * 1000);

      return () => {
        clearTimeout(timer);
        localStorage.setItem('STATUS_REQUIRE_WELCOME', 'false');
      }
    }
  }, [hideWelcome]);

  const onCollapse = (collapsed) => {
    setIsCollapsed(collapsed);
  };

  const switchRoutes = () => {
    return (
      <Switch>
        {dashboardRoutes.map((prop, key) => {
          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.pathTo} key={prop.path} />;
          }
          if (prop.collapse) {
            return prop.views.map((prop, key) => {
              return (
                <AuthenticatedRoute
                  path={prop.path}
                  component={prop.component}
                  exact={prop.exact}
                  key={prop.path}
                  props={{
                    isAuthenticated: isAuthenticated,
                    current_user: current_user,
                    reload_user: reload_user,
                  }}
                />
              );
            });
          }
          return (
            <AuthenticatedRoute
              path={prop.path}
              component={prop.component}
              exact={prop.exact}
              key={prop.path}
              props={{
                isAuthenticated: isAuthenticated,
                current_user: current_user,
                reload_user: reload_user,
              }}
            />
          );
        })}
      </Switch>
    );
  };

  const generate_sidebar = () => {
    return (
      <Menu
        theme="light"
        defaultSelectedKeys={['1']}
        mode="inline"
        style={{ height: '100%' }}
      >
        <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
          <NavLink to="/">
            <Icon type="user" />
            <span>Job Search</span>
          </NavLink>
        </Menu.Item>

        <SubMenu
          key="researchTools"
          icon={<SolutionOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Research Tools</span>
            </span>
          }
        >
          <Menu.Item key="research_companies">
            <NavLink to="/companies">Search Companies</NavLink>
          </Menu.Item>
          <Menu.Item key="research_savedJobs">
            <NavLink to="/jobs/saved-list">Saved Jobs</NavLink>
          </Menu.Item>
          <Menu.Item key="research_savedCompanies">
            <NavLink to="/companies/saved-list">Saved Companies</NavLink>
          </Menu.Item>
          <Menu.Item key="research_savedSearches">
            <NavLink to="/jobs/saved-searches">Saved Searches</NavLink>
          </Menu.Item>
        </SubMenu>

        <Menu.Item key="applicationsList" icon={<ProfileOutlined />}>
          <NavLink to="/applications/list">
            Applications
          </NavLink>
        </Menu.Item>

        <SubMenu
          key="myProfile"
          icon={<SolutionOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>My Profile</span>
            </span>
          }
        >
          {/* <Menu.Item key="profile_subscription">
            <NavLink to="/profile/subscription">Subscription</NavLink>
          </Menu.Item> */}
          <Menu.Item key="profile_jobProfiles">
            <NavLink to="/profile/job-profiles">Job Profiles</NavLink>
          </Menu.Item>
          <Menu.Item key="profile_documentList">
            <NavLink to="/profile/document-list">Document List</NavLink>
          </Menu.Item>
          <Menu.Item key="profile_contact">
            <NavLink to="/profile/contact">Contact Details</NavLink>
          </Menu.Item>
          <Menu.Item key="profile_subscription">
            <Tooltip title="Free to for limited time">
            <NavLink to="#">
              <span>My Subscription</span>
            </NavLink>
            </Tooltip>
          </Menu.Item>
        </SubMenu>

        {/* <SubMenu
          key="settings_sub"
          icon={<SettingOutlined />}
          title={
            <span>
              <Icon type="team" />
              <span>Settings</span>
            </span>
          }
        >
          {/*<Menu.Item key="subscriptions_view">*/}
          {/*  <NavLink to="/settings/subscription/1?tab=my_subscription">*/}
          {/*    My Subscription*/}
          {/*  </NavLink>*/}
          {/*</Menu.Item>*/}
          {/* <Menu.Item key="user_profile_sub">
            <NavLink to="/settings/user-profile/?tab=summary">
              <Icon type="user" />
              <span>My User Profile</span>
            </NavLink>
          </Menu.Item> */}
        {/* </SubMenu> */}
      </Menu>
    );
  };

  const currentStep = useMemo(() => {
    let step = 0;
    if (user && user.applicant) {
      if (user.applicant.startup_step1) {
        step ++;
      }
      if (user.applicant.startup_step2) {
        step += 2;
      } else return step;

      if (user.applicant.startup_step3) {
        step ++;
      } else return step;

      if (user.applicant.startup_step4) {
        step ++;
      }

      return step;
    }
    return 1;
  }, [user]);
  console.log('currentUser', user);

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {!hideWelcome && (
        <Welcome />
      )}
      <Space direction="vertical" style={{ backgroundColor: 'white' }}>
        <div className="logo" />
        <img
          src={require(!isCollapsed
            ? '../assets/img/brand/PlatinumJobSeeker_300px.png'
            : '../assets/img/brand/TickWithBorder_50px.png')}
          alt=""
          target="_blank"
          style={{
            paddingTop: 16,
            marginBottom: 16,
            maxHeight: 64,
            maxWidth: 200,
            marginLeft: isCollapsed ? 15 : 0,
            width: isCollapsed ? 50 : '100%',
          }}
        />
        <Sider
          theme="light"
          style={{minHeight: '100vh'}} 
          breakpoint="md"
          collapsedWidth="0"
          collapsible
          collapsed={isCollapsed}
          onCollapse={onCollapse}
        >
          {generate_sidebar()}
        </Sider>
      </Space>
      <Layout>
        <Header
          style={{
            background: '#fff',
            padding: 0,
          }}
        >
          <DashboardHeaderContainer
            isAuthenticated={isAuthenticated}
            userHasAuthenticated={userHasAuthenticated}
            handleLogout={handleLogout}
          />
        </Header>
        <Layout>
          <Content>
          <div className={classes.main_content}>
          <Alert
            message="LAUNCH SPECIAL"
            description={<span className={classes.alert_text}>Jobseekers who sign up now will have use of <strong>ALL</strong> site features <strong>FREE for limited time</strong> so come on! Give it a try!!</span>}
            type="success"
            showIcon
          />
            {/* <div
              style={{
                padding: 24,
                background: '#fff',
                minHeight: 360,
                height: '100%',
              }}
            > */}
              <Steps current={currentStep} style={{ marginTop: 16 }} className={classes.dashboard_steps}>
                <Step title="Register" />
                <Step title={<NavLink to="/my-job-profiles/new">Create Profile</NavLink>} />
                {/* <Step title={<NavLink to="/settings/subscription/1?tab=create_subscription">Subscribe</NavLink>} /> */}
                <Step title={<NavLink to="/jobs">Search Jobs</NavLink>} />
              </Steps>
              <div>{switchRoutes()}</div>
            {/* </div> */}
            </div>
          </Content>
        </Layout>
        <Footer style={{ textAlign: 'left' }}>
          <img
            src={require('../assets/img/brand/TickWithBorder_20px.png')}
            alt="tick-border"
          />
          <strong>
            Platinumjobseeker<sup>TM</sup>
          </strong>{' '}
          Jobseeker Portal © 2020-2022
          <br />
          <small>Version 1.0.0</small>
          <br/>
          <small style={{ textAlign: 'left' }}>
            <a
              href={'https://pjs-documents-public.s3.amazonaws.com/PJS+website+Terms+and+Conditions+27042022.pdf'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>{' '}
            |{' '}
            <a
              href={'https://pjs-documents-public.s3.amazonaws.com/PlatinumJobSeeker+Privacy+Policy+22022021.pdf'}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>{' '}
            |{' '}
            <a
              href={'https://www.platinumjobseeker.com/faq'}
              target="_blank"
              rel="noopener noreferrer"
            >
              FAQs
            </a>
          </small>{' '}
          <br/>
          <small>
          *Ts and Cs apply: jobseekers who sign up to PlatinumJobSeeker™ can search available jobs, load a pitch video, apply directly or indirectly for jobs, load their CVs, save searches, receive updates to searches, load their cover letters in a fully functional personal dashboard and are otherwise subject to the terms of use of the PJS website. Normal subscription conditions apply after the expiry of the limited time. A minimum of 30 days notice of the change will be posted to the site and emailed to members to advise in advance.
          </small>
          <Row>
            <Col xs={24} style={{ textAlign: 'right' }}>
              <small>
                Created by{' '}
                <a
                  href="https://deliostech.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Delios Technology Inc
                </a>
              </small>
            </Col>
          </Row>
        </Footer>
      </Layout>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    language: state.language,
    savedJobsCount: state.jobs.savedJobsCount,
    savedCompaniesCount: state.companies.savedCompaniesCount,
    user: state.auth.currentUser,
  };
};

const mapDispatchToProps = () => {
  return {};
};

const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);

export default withStyles(styles)(DashboardContainer);
