import React, { useState } from 'react';
import withStyles from 'react-jss';
import { Modal, Pagination } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const { confirm } = Modal;

const styles = {
  listView: {
    border: '1px solid #EBEDF0',
    marginBottom: 20,
    maxHeight: 550,
    overflow: 'auto',
    fontSize: 16,
  },
  listItem: {
    borderBottom: '1px solid #EBEDF0',
    padding: '15px 20px',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    marginBottom: 0,
    color: '#21B466',
    fontWeight: 700,
    marginRight: 15,
  },
  subTitle: { fontWeight: 'bold' },
  wrapper: { marginBottom: 25 },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formLabel: {
    padding: 5,
    display: 'block',
  }
};

const CompanyListView = ({
  classes,
  companies,
  countries,
  subscriptions
}) => {
  const [page, setPage] = useState(1);
  const history = useHistory();

  const countryName = countryId => {
    if (countries.length > 0) {
      return countries.find(item => item.id === countryId)?.full_name;
    }
    return '';
  };

  const handleClickRow = (row) => {
    history.push({ pathname: `/companies/view/${row.id}` });
  };

  const formatDescription = (str) => str && str.length > 255 ? `${str.slice(0, 255)}...` : str;

  return (
    <div className={classes.wrapper}>
      <div className={classes.listView}>
        {
          companies.slice((page - 1) * 10, page * 10).map(item => (
            <div key={item.id} className={classes.listItem} onClick={() => handleClickRow(item)}>
              <div className={classes.title} dangerouslySetInnerHTML={{ __html: item.workplace_name }} />
              <div>
                {item?.region},{' '}
                {countryName(item?.country_id)}
              </div>
              <br />
              <div dangerouslySetInnerHTML={{ __html: formatDescription(item?.short_description)}} />
              <div>Employees: {item.employee_count}</div>
            </div>
          ))
        }
      </div>
      <Pagination
        className={classes.pagination}
        current={page}
        onChange={setPage}
        total={companies.length}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    countries: state.countries,
    subscriptions: state.subscriptions,
  };
};

const mapDispatchToProps = { };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(CompanyListView));
