import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'react-jss';
import { IntlProvider } from 'react-intl';
import flatten from 'flat'
import createHistory from 'history/createBrowserHistory';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { Row, Spin } from 'antd';
import { getCountries } from './redux/actions/countries';
import { getJobClassifications } from './redux/actions/job_classifications';

import messages_en from './translations/en.json';
import messages_zh from './translations/zh.json';

import './App.css';
import AppliedRoute from './components/routes/AppliedRoute';
import indexRoutes from './routes/index.jsx';
import { signOut, validateToken } from './redux/actions/auth';
import { getSubscriptions } from './redux/actions/subscriptions';
import { getSavedJobsList } from './redux/actions/jobs';
import { getSavedCompaniesList } from './redux/actions/companies';

const history = createHistory();
ReactGA.initialize('UA-179934135-5');
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const messages = {
  'en': messages_en,
  'zh': messages_zh,
};

const theme = {
  label_font_weight: 500,
  input_disabled_color: '#333 !important',
  input_disbaled_background_color: '#fafafa !important',
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticating: false,
      initialStateSample: '',
      current_user: null,
      loading: true
    }
  }

  async componentDidMount() {
    this.setState({ loading: true });
    await this.props.validateTokenHandler();
    await this.props.getCountriesHandler();
    await this.props.getJobClassificationsHandler();
    await this.props.getSubscriptionsHandler();
    await this.props.getSavedJobsHandler();
    await this.props.getSavedCompaniesHandler();
    this.setState({ loading: false });
  }

  handleLogout = async () => {
    try {
      await this.props.signOutHandler();
    } catch (e) {
      alert(e.message);
    }
  };

  render() {
    //hardcode as true for template, change it when project starts
    const childProps = {
      isAuthenticated: this.props.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      set_current_user: true, //this.set_current_user,
      handleLogout: this.handleLogout,
      current_user: true, //this.state.current_user,
      reload_user: true //this.repopulateData
    };

    return (
      <IntlProvider
        locale={this.props.language.language_current_ui}
        messages={flatten(messages[this.props.language.language_current_ui])}>
        <ThemeProvider theme={theme}>
          {
            !this.state.loading ?
              <Router history={history}>
                <Switch>
                  {indexRoutes.map((prop, key) => {
                    return (
                      <AppliedRoute
                        path={prop.path}
                        component={prop.component}
                        key={key}
                        props={childProps}
                        exact={prop.exact}
                      />
                    );
                  })}
                </Switch>
              </Router> :
              <Row style={{ justifyContent: 'center', height: '100vh', display: 'flex', alignItems: 'center' }}>
                <Spin tip="Loading..." size='large'/>
              </Row>
          }
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    app_state: state.app_state,
    language: state.language,
    isAuthenticated: state.auth.isAuthenticated
  };
};

const mapDispatchToProps = ({
  signOutHandler: signOut,
  validateTokenHandler: validateToken,
  getCountriesHandler: getCountries,
  getJobClassificationsHandler: getJobClassifications,
  getSubscriptionsHandler: getSubscriptions,
  getSavedJobsHandler: getSavedJobsList,
  getSavedCompaniesHandler: getSavedCompaniesList,
});

const AppContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);

export default AppContainer;
