import { jobsType } from '../type';
import {
  getJobsAPI,
  getJobAPI,
  updateJobAPI,
  createJobAPI,
  getJobsStatusList,
  getSavedJobSearchesAPI,
  updateSavedSearchActiveAPI, getSavedJobsAPI,
} from '../../services/jobs';

const getList = (params) => {
  return (dispatch) => {
    return getJobsAPI(params)
      .then((data) => {
        return dispatch({
          type: jobsType.GET_JOBS,
          jobs: data.data
        });
      }).catch(error => {})
  }
};

const getStatusList = () => {
  return (dispatch) => {
    return getJobsStatusList()
    .then((data) => {
      return dispatch({
        type: jobsType.GET_JOB_STATUS_LIST,
        data: data.data
      });
    }).catch(error => {})
  }
};

const getJob = (id) => {
  return (dispatch) => getJobAPI(id)
    .then((data) => {
      return dispatch({
        type: jobsType.GET_JOB,
        job: data.data
      });
    }).catch(error => { })
}

const getSavedJobSearches = () => {
  return (dispatch) => {
    return getSavedJobSearchesAPI()
    .then((data) => {
      return dispatch({
        type: jobsType.GET_SAVED_JOB_SEARCHES,
        data: data.data
      });
    }).catch(error => {})
  }
};

const updateJob = (id, param) => {
  return updateJobAPI(id, param)
    .then((data) => {
      return data.data
    }).catch(error => {})
}

const createJob = (param) => {
  return createJobAPI(param)
    .then((data) => {
      return data.data
    }).catch(error => {})
}

const updateSavedSearchActive = (param) => {
  return (dispatch) => {
    return updateSavedSearchActiveAPI(param)
    .then((data) => {
      return dispatch({
        type: jobsType.GET_SAVED_JOB_SEARCHES,
        data: data.data
      });
    }).catch(error => {})
  }
}

const getSavedJobsList = (param) => {
  return (dispatch) => {
    return getSavedJobsAPI()
      .then((data) => {
        return dispatch({
          type: jobsType.GET_SAVED_JOB_LIST,
          data: data.data
        });
      }).catch(error => {})
  }
};

export {
  getList,
  getJob,
  updateJob,
  createJob,
  getStatusList,
  getSavedJobSearches,
  updateSavedSearchActive,
  getSavedJobsList,
}
