import React, { useEffect, useState } from 'react';
import { Card, PageHeader, Button } from 'antd';

import JobListView from '../../../components/pages/dashboard/jobs/JobListView';
import { getSavedJobsAPI, removeSavedJobsAPI } from '../../../services/jobs';
import { useDispatch, useSelector } from 'react-redux';
import { getSavedJobsList } from '../../../redux/actions/jobs';

function JobSavedListPage() {
  const dispatch = useDispatch();
  const { jobs } = useSelector(state => ({ jobs: state.jobs.savedJobs }));
  const [savedJobs, setSavedJobs] = useState([]);

  useEffect(() => {
    dispatch(getSavedJobsList());
  }, []);

  useEffect(() => {
    if (jobs) {
      setSavedJobs(jobs.map(item => item.job));
    }
  }, [jobs]);

  const onClickClearAll = async () => {
    try {
      const res = await removeSavedJobsAPI();
      if (res) {
        dispatch(getSavedJobsList());
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Jobs Management'
      />
      <Card
        style={{ minHeight: 360 }}
        title='Jobs I have not applied for or deleted'
        extra={[
          <Button key="1" onClick={onClickClearAll} type="primary">Clear All</Button>,
        ]}
      >
        <div style={{ paddingTop: 24 }}>
          <JobListView jobs={savedJobs} />
        </div>
      </Card>
    </div>

  );
}

export default JobSavedListPage;
