import { applicationsType } from '../type';

const initialState = [];

function applications(state = initialState, action) {
  switch (action.type) {
    case applicationsType.GET_APPLICATIONS:
      return action.applications;
    case applicationsType.CREATE_APPLICATION:
      return [...state, action.application];
    default:
      return state;
  }
}

export default applications;
