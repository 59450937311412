import * as R from 'ramda';
import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import withStyles from 'react-jss';
import queryString from 'query-string';
import { Row, Col, Button, Form, Select, Spin } from 'antd';
import {
  getApplicant,
  updateApplicant,
} from '../../../../redux/actions/applicant';
import { getJobProfilesList } from '../../../../redux/actions/jobProfiles';
import { createApplication } from '../../../../redux/actions/applications';
import { getDocuments } from '../../../../redux/actions/documents';
import { validateToken } from '../../../../redux/actions/auth';

const { Option } = Select;

const styles = (theme) => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
});

function ApplicationNewPageComponent(props) {
  const {
    createApplicationHandler,
    applicant,
    classes,
    history,
    location,
    user,
    getApplicantHandler,
    getJobProfilesListHandler,
    jobProfilesList,
    getDocuments,
    documents,
    validateTokenHandler,
  } = props;
  const [loading, setLoading] = useState(false);

  const params = queryString.parse(location.search);
  const jobId = params.jobId;

  const [summaryInfo, setSummaryInfo] = useState({
    job_id: jobId,
    application_date_time: new Date(),
    applicant_profile_id: null,
  });

  useEffect(() => {
    const initData = async () => {
      if (user && user.id) {
        setLoading(true);
        await getApplicantHandler(user.applicant_id);
        await getJobProfilesListHandler();
        await getDocuments();
        setLoading(false);
      }
    };
    initData();
  }, [user]);

  useEffect(() => {
    setSummaryInfo((prev) => ({
      ...prev,
      applicant_id: applicant,
    }));
  }, [applicant]);

  const documentsOptions = useMemo(
    () =>
      R.map(
        R.applySpec({
          label: R.pipe(
            R.paths([['documentCategorySub', 'fileBy'], ['documentTitle']]),
            R.join(' - '),
          ),
          value: R.prop('id'),
        }),
      )(documents),
    [documents],
  );

  const handleSubmit = async (event) => {
    const data = {
      ...summaryInfo,
      applicant_id: applicant.id,
    };
    createApplicationHandler(data).then(() => {
      validateTokenHandler();
      history.push('/applications/list');
    });
  };

  const handleProfile = (profile_id) => {
    setSummaryInfo((prev) => ({
      ...prev,
      applicant_profile_id: profile_id,
    }));
  };

  const handleChangeDocument = (value) => {
    setSummaryInfo((prev) => ({
      ...prev,
      application_documents: value,
    }));
  };
  const profileOptions = jobProfilesList.map((jobProfiles) => (
    <Option key={jobProfiles.id} value={jobProfiles.id}>
      {Boolean(jobProfiles?.public_fileBy) 
        ? `${jobProfiles.file_by} [${jobProfiles.public_fileBy}]`
        : `${jobProfiles?.file_by}`
      }
    </Option>
  ));
  const applicant_profile = useMemo(() => {
    return jobProfilesList.find(item => item.id === summaryInfo?.applicant_profile_id);
  }, [summaryInfo?.applicant_profile_id, jobProfilesList]);

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Form.Item
                label="Select your profile for this application"
                className={classes.form_label}
                name="Applicant Profile Id"
                rules={[{ required: true }]}
              >
                <Select
                  style={{ width: '100%' }}
                  onChange={handleProfile}
                  tokenSeparators={[',']}
                  value={summaryInfo.applicant_profile_id}
                >
                  {profileOptions}
                </Select>
              </Form.Item>
              <div dangerouslySetInnerHTML={{ __html: applicant_profile?.profileHtml }} />
            </Col>
            <Col xs={12}>
              <Form.Item label="Select documents to attach to this application" className={classes.form_label}>
                <Select
                  style={{ width: '100%' }}
                  onChange={handleChangeDocument}
                  mode="multiple"
                >
                  {documentsOptions.map(({ value, label }) => (
                    <Select.Option key={value} value={value} label={label}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Row gutter={[96, 24]}>
                <Col xs={24} style={{ textAlign: 'right' }}>
                  <Button type="primary" htmlType="submit">
                    Apply Now
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Spin>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    applicant: state.applicant,
    user: state.auth.currentUser,
    jobProfilesList: state.jobProfiles.jobProfilesList,
    documents: state.documents,
  };
};

const mapDispatchToProps = {
  getApplicantHandler: getApplicant,
  updateApplicantHandler: updateApplicant,
  getJobProfilesListHandler: getJobProfilesList,
  createApplicationHandler: createApplication,
  getDocuments: getDocuments,
  validateTokenHandler: validateToken,
};

export default withStyles(styles, { injectTheme: true })(
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ApplicationNewPageComponent),
  ),
);
