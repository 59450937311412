const GET_JOB_PROFILES = 'GET_JOB_PROFILES';
const GET_JOB_PROFILES_LIST = 'GET_JOB_PROFILES_LIST';
const UPDATE_JOB_PROFILES = 'UPDATE_JOB_PROFILES';
const CREATE_JOB_PROFILES = 'CREATE_JOB_PROFILES';

export default {
  GET_JOB_PROFILES,
  GET_JOB_PROFILES_LIST,
  UPDATE_JOB_PROFILES,
  CREATE_JOB_PROFILES,
}
