import React, { Component } from 'react';
import withStyles from 'react-jss'

import { connect } from "react-redux";

import { setLanguageSelectId } from "../../../redux/actions/language";

// import { API, Auth } from "aws-amplify";
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Input } from 'antd';
import {
  PageHeader,
  Button,
  // Descriptions
} from 'antd';

import { Select } from 'antd';
import ReactCountryFlag from "react-country-flag"

const { Option } = Select;

const styles = {
  heading: {
    color: '#c30f48',
    fontWeight: 'bold',
  },
  headingText: {
    fontWeight: "bold",
    '@media (max-width: 420px)': {
      display: "none"
    }
  },
  headingLogo: {
    height: "30px",
    paddingLeft: 2,
    paddingRight: 20,
    '@media (min-width: 420px)': {
      display: "none",
    },
  },
}
class PagesHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handle_language_select = (value) => {
    this.props.setLanguageSelectId(value);
  }

  handleLogout = async event => {
    console.log(event);
    this.props.handleLogout();
  }

  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <PageHeader
          ghost={false}
          className={classes.heading}
          // onBack={() => window.history.back()}
          title={<React.Fragment>
            <img
              src={require('../../../assets/img/brand/PlatinumJobSeeker_300px.png')}
              alt=''
              target='_blank'
              className={classes.headingLogo}
            />
            <span className={classes.headingText}>
            <div>Welcome - and thanks for using Platinumjobseeker<sup>TM</sup></div>
            </span>
          </React.Fragment>}
          // subTitle="This is a subtitle"
          extra={this.props.isAuthenticated ? [
            // <Select
            //   key="1"
            //   defaultValue={this.props.language.language_current_ui}
            //   style={{ width: 180 }}
            //   onChange={this.handle_language_select}>
            //   <Option value="en">
            //     <ReactCountryFlag
            //       className="emojiFlag"
            //       countryCode="GB"
            //       style={{
            //         fontSize: '1.5em',
            //         lineHeight: '1.5em',
            //       }}
            //       aria-label="English Language"
            //     /> English
            //   </Option>
            //   <Option value="zh">
            //     <ReactCountryFlag
            //       // className="emojiFlag"
            //       countryCode="CN"
            //       style={{
            //         fontSize: '1.5em',
            //         lineHeight: '1.5em',
            //       }}
            //       aria-label="Chinese Traditional Language"
            //     /> 繁體中文
            //   </Option>
            // </Select>,
            <Button key="2" type="primary" onClick={this.handleLogout}>
              Log Out
          </Button>] : []}
        >
        </PageHeader>
      </React.Fragment>
    );
  }
}

// export default DashboardHeader;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguageSelectId: (language_id) => {
      dispatch(setLanguageSelectId(language_id))
    }
  }
}

const PagesHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PagesHeader);

export default withStyles(styles)(PagesHeaderContainer);
