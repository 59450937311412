import React, { useEffect, useState } from 'react';
import { Modal, Pagination } from 'antd';
import withStyles from 'react-jss';
import { appStateSelector } from '../../../../redux/selectors';
import { getCountries } from '../../../../redux/actions/countries';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const styles = {
  listView: {
    border: '1px solid #EBEDF0',
    marginBottom: 20,
    maxHeight: 550,
    overflow: 'auto',
    fontSize: 16,
  },
  listItem: {
    borderBottom: '1px solid #EBEDF0',
    padding: '15px 20px',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    marginBottom: 0,
    color: '#21B466',
    fontWeight: 700,
    marginRight: 15,
  },
  subTitle: { fontWeight: 'bold' },
  wrapper: { marginBottom: 25 },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  emptyState: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 300,
  },
};

const { confirm } = Modal;

const JobListView = ({
  classes,
  jobs,
  countries,
  getCountriesListHandler,
  subscriptions,
}) => {
  const [page, setPage] = useState(1);
  const history = useHistory();

  useEffect(() => {
    getCountriesListHandler();
  }, []);

  const countryName = (countryId) => {
    if (countries.length > 0) {
      return countries.find((item) => item.id === countryId)?.full_name;
    }
    return '';
  };

  const handleClickRow = (row) => {
    history.push({ pathname: `/jobs/view/${row.id[0] === 'E' ? 'ADZ' : 'PJS'}/${row.id}` });
  };

  if (!jobs.length) {
    return (
      <div className={classes.emptyState}>
        No data
      </div>
    )
  }
  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.listView}>
          {jobs
            .slice((page - 1) * 2, page * 2)
            .map((item) => (
              <div key={item.id} className={classes.listItem} onClick={() => handleClickRow(item)}>
                <div className={classes.header}>
                  <div className={classes.title} dangerouslySetInnerHTML={{ __html: item.job_title }} />
                  <img src={require('../../../../assets/img/brand/platinum_tick.png')} alt='' height={25} />
                </div>
                <div className={classes.subTitle}>{item?.job_classification?.file_by}</div>
                <br />
                <div>
                  <em>{item?.workplace_profiles?.workplace_name}</em>
                </div>
                <div>
                  {item?.location_region},{' '}
                  {countryName(item?.location_country_id)}
                </div>
                <br />
                <div>{item?.short_description}</div>
              </div>
            ))}
        </div>
        <Pagination
          pageSize={2}
          className={classes.pagination}
          current={page}
          onChange={setPage}
          total={jobs.length}
        />
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    language: state.language,
    countries: state.countries,
    current_account_id: appStateSelector(state).current_account_id,
    subscriptions: state.subscriptions,
  };
};

const mapDispatchToProps = { getCountriesListHandler: getCountries };

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(JobListView));
