import React, { Component } from 'react';
import { Card, PageHeader, Button } from 'antd';

import CompanySavedListPageComponent from '../../../components/pages/dashboard/companies/CompanySavedListPageComponent';

class CompanySavedListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickShowAll = () => {
    this.props.history.push('/companies');
  }

  onClickSavedCompanies = () => {
    this.props.history.push('/companies/saved-list');
  }

  render() {
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title='Company Management'
        />
        <Card
          style={{ minHeight: 360 }}
          title='Saved Company List'
          extra={[
            <Button key="3" onClick={this.onClickSavedCompanies} type="primary">Saved Companies</Button>,
            <Button key="1" onClick={this.onClickShowAll}>Show All</Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <CompanySavedListPageComponent />
          </div>
        </Card>
      </div>

    );
  }
}

export default CompanySavedListPage;
