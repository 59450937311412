import React, { Component } from 'react';
import { Auth } from "aws-amplify";
import RegisterPageComponent from '../../components/pages/pages/RegisterPageComponent';
class RegisterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultStateSample: "",
      collapsed: false,
      email: "",
      password: "",
    }
  }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({ collapsed });
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async () => {
    try {
      await Auth.signIn(this.state.email.toLowerCase(), this.state.password);
      this.props.userHasAuthenticated(true);

    } catch (e) {
      console.log(e);
    }
  }

  handleLogout = () => {
    this.props.handleLogout();
  }

  render() {
    return (
      <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
        <RegisterPageComponent />
      </div>
    );
  }
}

export default RegisterPage;
