import React, {Component} from 'react';
import { PageHeader } from 'antd';

import CompanySearchPageComponent from '../../../components/pages/dashboard/companies/CompanySearchPageComponent';

class CompanySearchPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickShowAll = () => {
    this.props.history.push('/companies');
  };

  onClickSavedCompanies = () => {
    this.props.history.push('/companies/saved-list');
  };

  render() {
    return (
      <div
        style={{
          padding: 24,
          background: '#fff',
          minHeight: 360
        }}
      >
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title='Company Management'
        />
        <div style={{paddingTop: 24}}>
          <CompanySearchPageComponent />
        </div>
      </div>
    );
  }
}

export default CompanySearchPage;
