import { getUserAPI, updateUserAPI } from '../../services/applicant';
import { applicantType } from '../type';

const getApplicant = (id) => {
  return (dispatch) => {
    return getUserAPI(id)
      .then((data) => {
        if (data) {
          return dispatch({
            type: applicantType.GET_APPLICANT,
            applicant: data.data
          });
        } else console.log(data.msg);
      })
      .catch(error => {
        console.log(error);
	  })
  }
};

const updateApplicant = (id, params) => {
  return (dispatch) => {
    return updateUserAPI(id, params)
      .then((data) => {
        if (data) {
          return dispatch({
            type: applicantType.UPDATE_APPLICANT,
            applicant: data.data
          });
        } else console.log(data.msg);
      })
      .catch(error => {
        console.log(error);
	  })
  }
};

export {
  getApplicant,
  updateApplicant
}