
// import {
//   moduleName as adminModule
// } from './ducks/admins';

// import {moduleName as applicantModule} from './ducks/applicants';

// import {
//   moduleName as advertiserModule
// } from './ducks/advertisers';

export const appStateSelector = state => state.app_state;

// export const adminSelector = state => state[adminModule];

export const applicantSelector = state => state.applicants;

// export const advertiserSelector = state => state[advertiserModule];
