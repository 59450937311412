import React from 'react';
import { PageHeader } from 'antd';
import JobSavedSesarchesComponent from '../../../components/pages/dashboard/jobs/JobSavedSesarchesComponent';

function JobSavedSearchesPage (props) {
  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360,
      height: '100%'
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Saved Searches'
      />
      <JobSavedSesarchesComponent />
    </div>
  )
}

export default JobSavedSearchesPage;