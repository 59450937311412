import { API } from "aws-amplify";

export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";
export const UPDATE_NEW_CLIENT = "UPDATE_NEW_CLIENT";
export const DATA_CLIENT_STATUS = "DATA_CLIENT_STATUS";
export const DATA_COUNTRIES = "DATA_COUNTRIES";

export function onLoginSuccess(user) {
  return async function(dispatch) {
    try {
      const { applicant_record, user_record, applicant_access } = await get_current_client_details(user);
      const countries_list = await get_countries();
      if (applicant_record)
        dispatch(updateCurrentClientIntoStore(applicant_record, user_record, applicant_access));
      if (countries_list)
        dispatch(updateCountriesIntoStore(countries_list));
    } catch (e) {
      console.log(e);
    }
  };
}

async function get_current_client_details(user) {
  console.log(user);
  if (user.username) {
    return API.get(
      "users",
      `/get/aws-cognito/${user.username}`
    )
      .then(response => {
        if (!response.payload.user_applicant) {
          console.error("Error: No matching authentication details.")
        }
        return {
          applicant_record: response.payload.applicant,
          user_record: response.payload.user_applicant,
          applicant_access: response.payload.applicant_access
        };
      })
      .catch(error => {
        console.error(error);
        return API.get(
          "users",
          `/get/aws-cognito/${user.username}`
        )
          .then(response => {
            if (!response.payload.user_applicant) {
              console.error("Error: No matching authentication details.")
            }
            return {
              applicant_record: response.payload.applicant,
              user_record: response.payload.user_applicant,
              applicant_access: response.payload.applicant_access
                };
          })
          .catch(error => {
            console.log(error);
          })
      })
  }
  else if (user.email) {
    return API.get(
      "users",
      `/get-by-email/${user.email}`
    )
      .then(response => {
        return response.payload;
      })
      .catch(error => {
        console.log(error);
      })
  }
}

async function get_countries() {
  return API.get(
    "countries",
    `/list`
  )
    .then(response => {
      return response.payload;
    })
    .catch(error => {
      console.log(error);
    })
}

export function updateCountriesIntoStore(countries) {
  return {
    type: DATA_COUNTRIES,
    countries: countries,
  };
}
export function updateCurrentClientIntoStore(client_record, user_record, client_access) {
  return {
    type: UPDATE_CURRENT_USER,
    user_id: user_record.id,
    client_id: client_record.id,
    current_client_record: client_record,
    current_user_record: user_record,
    client_access: client_access
  };
}
export function updateClientStatusListIntoStore(data_client_status_list) {
  return {
    type: DATA_CLIENT_STATUS,
    data_client_status_list: data_client_status_list,
  };
}
