import React, { useEffect, useState } from 'react';
import { Spin, Button, Tooltip, Space, notification } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getJobAPI, isSavedJobApi, saveJobAPI, unmarkSavedJobApi } from '../../../services/jobs';
import { isJobAppliedAPI } from '../../../services/applications';
import { getSavedJobsList } from '../../../redux/actions/jobs';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

function PJSJobViewPage (props) {
  const { match: { params: { job_id } } } = props;
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState();
  const history = useHistory();
  const user = useSelector(state => state.auth.currentUser);
  const [isSaved, setIsSaved] = useState(false);
  const [application, setApplication] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const initData = async () => {
      try {
        setLoading(true);
        const currentJob = await getJobAPI(job_id);
        if (currentJob?.data) {
          setJob(currentJob?.data);
        } else {
          history.push('/jobs');
        }
        const res = await isJobAppliedAPI({
          applicantId: user?.applicant_id,
          jobId: job_id
        });
        if (res && res.data) {
          setApplication(res.data);
        }
        setLoading(false);
      } catch (e) {
        history.push('/jobs');
      }
    };

    initData();
  }, [job_id, user]);

  useEffect(() => {
    fetchSavedJob();
  }, [job_id, user]);

  const fetchSavedJob = () => {
    isSavedJobApi(job_id, user?.applicant_id)
      .then(res => {
        setIsSaved(res.data);
      })
      .catch(err => {
        setIsSaved(false);
      });
  };

  const onSaveJob = () => {
    saveJobAPI({
      jobId: job_id,
      applicantId: user?.applicant_id
    })
      .then(data => {
        openNotificationWithIcon(
          'success',
          'Save Job',
          'Success saving your job!',
        );
        fetchSavedJob();
        dispatch(getSavedJobsList());
      })
      .catch(err => {
        if (err.message === 'Already exist') {
          openNotificationWithIcon(
            'error',
            'Save Job',
            'This job is already saved!',
          );
        } else {
          openNotificationWithIcon(
            'error',
            'Save Job',
            'Failed saving your job!',
          );
        }
      })
  };

  const unmarkSavedJob = () => {
    unmarkSavedJobApi({
      jobId: job_id,
      applicantId: user?.applicant_id
    })
      .then(data => {
        openNotificationWithIcon(
          'success',
          'Unmark Saved Job',
          'Success unmark saved job!',
        );
        fetchSavedJob();
        dispatch(getSavedJobsList());
      })
      .catch(err => {
        openNotificationWithIcon(
          'error',
          'Unmark Saved Job',
          'Failed unmark saved job!',
        );
      })
  };

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360,
      height: '100%'
    }}>
      <Spin
        spinning={loading}
        tip="Loading...">

        <div dangerouslySetInnerHTML={{ __html: job?.advertisement_text }} />
        <Space size={16} align="baseline">
          <Tooltip title="Apply Now">
            <Button
              type="primary"
              onClick={() => history.push(`/applications/new?jobId=${job.id}`)}
              disabled={!!application}
            >
              Apply Now
            </Button>
          </Tooltip>
          {
            isSaved ? (
              <Tooltip title="Unmark Saved Job">
                <Button
                  type="danger"
                  onClick={unmarkSavedJob}
                >
                  Delete
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Save Job for Later">
                <Button
                  type="primary"
                  onClick={onSaveJob}
                >
                  Save Job for Later
                </Button>
              </Tooltip>
            )
          }
        </Space>
        {
          !!application && (
            <div style={{ marginTop: 16 }}>
              This job has been applied for on {moment(application.application_date_time).format('DD/MM/YYYY')}. {' '}
              <Link to={`/applications/view/${application.id}`}>Click here</Link>
              {' '}to view application
            </div>
          )
        }
      </Spin>
    </div>
  )
}

export default PJSJobViewPage;
