import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const createLoggingAPI = (params) => {
  const path = '/applicant/logging/create';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendRequest(params);
};

const updateApplicantLoggingRecordsAPI = (id, params) => {
  const path = `/applicant/logging/applicants-records/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
}

const updateApplicantRegisterRecordsAPI = (id, params) => {
  const path = `/applicant/register/records/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
}

export {
  createLoggingAPI,
  updateApplicantLoggingRecordsAPI,
  updateApplicantRegisterRecordsAPI
};