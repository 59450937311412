import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const subscriptionPricesAPI = () => {
  const path = `/applicant/v1/payment/subscription-prices`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

const checkoutSubscriptionAPI = (params) => {
  const path = `/applicant/v1/payment/checkout-subscription`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
    params,
  );
};

const isValidSubscriptionAPI = () => {
  const path = `/applicant/v1/payment/valid-subscription`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

const applicantSubscriptionAPI = () => {
  const path = `/applicant/v1/payment/applicant-subscription`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(
    APIConstants.AuthenticationType.BEARER,
  );
};

export {
  subscriptionPricesAPI,
  checkoutSubscriptionAPI,
  isValidSubscriptionAPI,
  applicantSubscriptionAPI,
};
