import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Checkbox,
  Upload,
  notification,
  Spin,
  message,
  Slider,
  Space,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';

import { Utils } from '../../../../helpers';
import APIUtils from '../../../../helpers/api/utils';
import {
  getJobProfiles,
  updateJobProfiles,
  createJobProfiles,
} from '../../../../redux/actions/jobProfiles';
import CountrySelectComponent from '../../../common/CountrySelectComponent';
import { validateToken } from '../../../../redux/actions/auth';

const { Dragger } = Upload;
const marks = {
  0: '0',
  25000: '25,000',
  50000: '50,000',
  75000: '75,000',
  100000: '100,000',
  125000: '125,000',
  150000: '150,000',
  175000: '175,000',
  200000: '200,000',
};
const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const JobProfileViewComponent = (props) => {
  const {
    getJobProfilesHandler,
    jobProfileStore,
    updateJobProfilesHandler,
    createJobProfilesHandler,
    validateTokenHandler,
    id,
  } = props;
  const countries = useSelector((state) => state.countries);
  const history = useHistory();
  const [jobProfile, setJobProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  useEffect(() => {
    if (id) {
      setLoading(true);
      getJobProfilesHandler(id)
        .then((data) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [getJobProfilesHandler, id]);

  useEffect(() => {
    if (!jobProfileStore) return;

    const { pitch_video_file_id } = jobProfileStore;
    if (id) {
      setJobProfile(jobProfileStore);
    }
    if (id && jobProfileStore && pitch_video_file_id) {
      setVideoList([
        {
          ...JSON.parse(pitch_video_file_id),
          uid: 'applicant-uv-1',
        },
      ]);
    }
  }, [jobProfileStore, id]);

  const getCountryFullName = (country_id) => {
    return (
      countries?.filter((country) => country.id === country_id)[0].full_name ||
      ''
    );
  };
  const onSubmit = () => {
    setLoading(true);
    if (id) {
      updateJobProfilesHandler(id, {
        ...jobProfile,
        profileHtml: initHtml,
      })
        .then((data) => {
          openNotificationWithIcon(
            'success',
            'Update Successful',
            'Your Job Profile has been updated successfully',
          );
          setLoading(false);
        })
        .catch((error) => {
          openNotificationWithIcon(
            'error',
            'Update Failed',
            'Your Job Profile has been updated failed!',
          );
          setLoading(false);
        });
    } else {
      createJobProfilesHandler({
        ...jobProfile,
        profileHtml: initHtml,
      })
        .then((data) => {
          openNotificationWithIcon(
            'success',
            'Create Successful',
            'Your Job Profile has been created successfully',
          );
          setLoading(false);
          validateTokenHandler();
          history.push('/dashboard');
        })
        .catch((error) => {
          openNotificationWithIcon(
            'error',
            'Create Failed',
            'Your Job Profile has been created failed!',
          );
          setLoading(false);
        });
    }
  };

  const handleChangeCheckbox = (event) => {
    const { checked, id } = event.target;
    setJobProfile((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setJobProfile((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleChangeStreetAddress = (value) => {
    setJobProfile((prev) => ({
      ...prev,
      street_address_country_id: value,
    }));
  };

  const token = APIUtils.getAccessToken();

  const uploadVideoProps = {
    name: 'file',
    multiple: false,
    accept:
      '.webm, .mkv, .flv, .vob, .mp4, .ogv, .ogg, .drc, .avi, .mov, .wmv,	.rmvb, .rm, .m4p, .m4v, .mpg, mp2, .mpeg, .mpe, .mpv, .flv .f4v .f4p .f4a .f4b, .3g2, .3gp, .svi',
    action: () => `${Utils.serverUrl.API_BASE_URL}/applicant/v1/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    fileList: videoList,
    beforeUpload: (file) => {
      if (file.size > 1024 * 1024 * 10) {
        message.error('Image must smaller than 10MB!');
        return false;
      }
      return true;
    },
    onChange(info) {
      if (info.file.size > 1024 * 1024 * 10) {
        return;
      }
      const { status } = info.file;
      setVideoList([info.file]);
      if (status !== 'uploading') {
        setJobProfile((prev) => ({
          ...prev,
          pitch_video_file_id: JSON.stringify({
            name: info.file.name,
            path: info.file.response.Location,
          }),
        }));
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        if (jobProfile.pitch_video_file_id) {
          setVideoList([JSON.parse(jobProfile.pitch_video_file_id)]);
        }
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleSalaryChange = (e) => {
    setJobProfile((prev) => ({
      ...prev,
      target_salary_min: e[0],
      target_salary_max: e[1],
    }));
  };

  const concatFullName = (firstName, secondName) => {
    return ((firstName || '') + " " + (secondName || ''));
  };

  const initHtml = useMemo(() => {
    const address = `
      ${
        jobProfile?.street_address_line_1
          ? `<div>${jobProfile?.street_address_line_1}</div>`
          : ''
      }
      ${
        jobProfile?.street_address_line_2
          ? `<div>${jobProfile?.street_address_line_2}</div>`
          : ''
      }
      ${
        jobProfile?.street_address_suburb
          ? jobProfile?.street_address_suburb + '. '
          : ''
      }
      ${
        jobProfile?.street_address_state
          ? jobProfile?.street_address_state + '. '
          : ''
      }
      ${
        jobProfile?.street_address_postcode
          ? jobProfile?.street_address_postcode
          : ''
      }
      ${
        jobProfile?.street_address_country_id
          ? `<div>${getCountryFullName(
              jobProfile?.street_address_country_id,
            )}</div>`
          : ''
      }
    `;

    return `
      <div>
        <span style="font-size: 18px; margin-right: 15px;">
          <strong>${
            jobProfile?.public_fileBy || 'No Profile Short Name provided'
          }</strong>
        </span>  
      </div>
      <table style="margin-top: 15px;">
        <tr>
          <td>Name</td>
          <td style="padding: 5px 20px;">${
            jobProfile.first_name || jobProfile.last_name
              ? concatFullName(jobProfile.first_name, jobProfile.last_name)
              : 'None Provided'
          }</td>
        </tr>
        <tr>
          <td>Email</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.email || 'None Provided'
          }</td>
        </tr>
        <tr>
          <td style="padding: 5px 0px; vertical-align: baseline">Address</td>
          <td style="padding: 5px 20px;">
            ${address.trim() ? address : 'None Provided'}
          </td>
        </tr>
        <tr>
          <td>Telephone Mobile</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.telephone_mobile || 'None Provided'
          }</td>
        </tr>
      </table>
      <h3 style="margin-top: 15px;">Make my profile visible to:</h3>
      <table>
        <tr>
          <td>Head Hunters (I am open to an offer)</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.visible_search ? 'YES' : 'NO'
          }</td>
        </tr>
        <tr>
          <td>People I send my application to</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.visible_applications ? 'YES' : 'NO'
          }</td>
        </tr>
      </table>
      <h3 style="margin-top: 15px;">Make my Pitch Video visible to:</h3>
      <table>
        <tr>
          <td>Head Hunters (I am open to an offer)</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.video_visible_search ? 'YES' : 'NO'
          }</td>
        </tr>
        <tr>
          <td>People I send my application to</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.video_visible_applications ? 'YES' : 'NO'
          }</td>
        </tr>
      </table>
      <h3 style="margin-top: 15px;">Available Work Options</h3>
      <table>
        <tr>
          <td>Work from home</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.available_from_home ? 'YES' : 'NO'
          }</td>
        </tr>
        <tr>
          <td>Full time</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.available_full_time ? 'YES' : 'NO'
          }</td>
        </tr>
        <tr>
          <td>Part time</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.available_part_time ? 'YES' : 'NO'
          }</td>
        </tr>
        <tr>
          <td>Flexible hours</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.available_flexible ? 'YES' : 'NO'
          }</td>
        </tr>
        <tr>
          <td>Willing to relocate</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.available_willing_relocate ? 'YES' : 'NO'
          }</td>
        </tr>
      </table>
      <table style="margin-top: 15px;">
        <tr>
          <td>Salary Range:</td>
          <td style="padding: 5px 20px;">${(
            (jobProfile?.target_salary_min || 0) * 1000
          ).toLocaleString()}-${(
      (jobProfile?.target_salary_max || 0 ) * 1000
    ).toLocaleString() || 0} p/a</td>
        </tr>      
        <tr>
          <td>Platinum Tick™Jobs Only</td>
          <td style="padding: 5px 20px;">${
            jobProfile?.platinum_tick_only ? 'YES' : 'NO'
          }</td>
        </tr>
      </table>
      <div style="margin-top: 15px;">
        ${
          videoList.length > 0
            ? '<video src="' +
              videoList[0].path +
              '"' +
              ' controls="true"></video>'
            : '<p>No Pitch Video has been uploaded</p>'
        }
      </div>
    `;
  }, [jobProfile]);

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Form layout="vertical" onFinish={onSubmit}>
          <Row gutter={[96, 24]}>
            <Col span={12}>
              <Row>
                <Col span={12}>
                  <Form.Item 
                    label="Profile Name (public)"
                    name="Profile Name (public)"
                    rules={[{ required: true }]}
                  >
                    <Input
                      id="file_by"
                      value={jobProfile?.public_fileBy}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item label="Profile Name (private)">
                    <Input
                      id="file_by"
                      value={jobProfile?.file_by}
                      onChange={handleChange}
                    />
                  </Form.Item>
                  <Form.Item
                    label="First Name"
                    name="First Name"
                    rules={[{ required: true }]}
                  >
                    <Input
                      id="first_name"
                      value={jobProfile?.first_name}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Last Name"
                    name="Last Name"
                    rules={[{ required: true }]}
                  >
                    <Input
                      id="last_name"
                      value={jobProfile?.last_name}
                      onChange={handleChange}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[{ required: true }]}
                  >
                    <Input
                      id="email"
                      value={jobProfile?.email}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Street Address (Line 1)">
                <Input
                  id="street_address_line_1"
                  value={jobProfile?.street_address_line_1}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item label="Street Address (Line 2)">
                <Input
                  id="street_address_line_2"
                  value={jobProfile?.street_address_line_2}
                  onChange={handleChange}
                />
              </Form.Item>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="Suburb / Region">
                    <Input
                      id="street_address_suburb"
                      value={jobProfile?.street_address_suburb}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={8}>
                  <Form.Item label="State" style={{ marginBottom: 0 }}>
                    <Input
                      id="street_address_state"
                      value={jobProfile?.street_address_state}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item label="PostCode">
                    <Input
                      id="street_address_postcode"
                      value={jobProfile?.street_address_postcode}
                      onChange={(event) => handleChange(event)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item label="Country">
                <CountrySelectComponent
                  id="street_address_country_id"
                  value={jobProfile?.street_address_country_id}
                  handleChange={handleChangeStreetAddress}
                />
              </Form.Item>
              <Form.Item label="Mobile / Cell">
                <Input
                  id="telephone_mobile"
                  value={jobProfile?.telephone_mobile}
                  onChange={(event) => handleChange(event)}
                />
              </Form.Item>
              <Form.Item label="Make my profile visible to:">
                <Space direction="vertical">
                  <Checkbox
                    id="visible_search"
                    onChange={handleChangeCheckbox}
                    checked={jobProfile?.visible_search}
                  >
                    Head Hunters (I am open to an offer)
                  </Checkbox>
                  <Checkbox
                    id="visible_applications"
                    style={{ marginLeft: 0, marginTop: 5 }}
                    onChange={handleChangeCheckbox}
                    checked={jobProfile?.visible_applications}
                  >
                    People I send my application to
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item label="Make my Pitch Video visible to:">
                <Space direction="vertical">
                  <Checkbox
                    id="video_visible_search"
                    onChange={handleChangeCheckbox}
                    checked={jobProfile?.video_visible_search}
                  >
                    Head Hunters (I am open to an offer)
                  </Checkbox>
                  <Checkbox
                    id="video_visible_applications"
                    style={{ marginLeft: 0, marginTop: 5 }}
                    onChange={handleChangeCheckbox}
                    checked={jobProfile?.video_visible_applications}
                  >
                    People I send my application to
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item label="Available Work Options">
                <Space direction="vertical">
                  <Checkbox
                    id="available_from_home"
                    onChange={handleChangeCheckbox}
                    checked={jobProfile?.available_from_home}
                  >
                    Available to work from home
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: 0, marginTop: 5 }}
                    id="available_full_time"
                    onChange={handleChangeCheckbox}
                    checked={jobProfile?.available_full_time}
                  >
                    Available to work full time
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: 0, marginTop: 5 }}
                    id="available_part_time"
                    onChange={handleChangeCheckbox}
                    checked={jobProfile?.available_part_time}
                  >
                    Available to work part time
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: 0, marginTop: 5 }}
                    id="available_flexible"
                    onChange={handleChangeCheckbox}
                    checked={jobProfile?.available_flexible}
                  >
                  Available to work flexible hours
                  </Checkbox>
                  <Checkbox
                    style={{ marginLeft: 0, marginTop: 5 }}
                    id="available_willing_relocate"
                    onChange={handleChangeCheckbox}
                    checked={jobProfile?.available_willing_relocate}
                  >
                    Willing to relocate
                  </Checkbox>
                </Space>
              </Form.Item>
              <Form.Item label="Target Salary">
                <Slider
                  marks={marks}
                  step={5000}
                  range
                  max={200000}
                  value={[
                    jobProfile?.target_salary_min,
                    jobProfile?.target_salary_max,
                  ]}
                  onChange={handleSalaryChange}
                />
              </Form.Item>

              {/* <Form.Item label="Platinum Tick™Jobs Only">
                <Checkbox
                  id="platinum_tick_only"
                  onChange={handleChangeCheckbox}
                  checked={jobProfile?.platinum_tick_only}
                >
                  I am only interested in Platinum Tick™jobs with a guarantee of
                  a response from the site or advertiser
                </Checkbox>{' '}
              </Form.Item> */}
              <Form.Item
                label="Video Upload"
                tooltip="Click to browse or drop video file"
              >
                <Dragger {...uploadVideoProps}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p
                    className="ant-upload-hint"
                    style={{ fontStyle: 'italic', padding: 10 }}
                  >
                    Your video will be screened by the site for allowable
                    content before being posted to the site. You may change your
                    video any number of times, but a replacement will
                    permanently delete any prior content.
                    <br />
                    <strong>File limit 10Mb size, 20 second length</strong>
                  </p>
                </Dragger>
              </Form.Item>
            </Col>
            <Col span={12}>
              <div dangerouslySetInnerHTML={{ __html: initHtml }} />
            </Col>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit">
                Save the Profile
              </Button>
            </Col>
          </Row>
        </Form>
      </Spin>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    countries: state.countries,
    jobProfileStore: state.jobProfiles.jobProfiles,
  };
};

const mapDispatchToProps = {
  getJobProfilesHandler: getJobProfiles,
  updateJobProfilesHandler: updateJobProfiles,
  createJobProfilesHandler: createJobProfiles,
  validateTokenHandler: validateToken,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobProfileViewComponent);
