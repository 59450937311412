import React from 'react';

function ApplicantProfileTap(props) {
  const { applicantProfile } = props;
  const { profileHtml } = applicantProfile;

  if (!profileHtml || !profileHtml.trim()) {
    return <div>No Profile Html</div>
  }

  return (
    <div dangerouslySetInnerHTML={{ __html: profileHtml }} />
  );
}

export default ApplicantProfileTap;
