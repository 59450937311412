import React from 'react';

import {
  Card,
  PageHeader
} from 'antd';

import JobProfileViewComponent from '../../../components/pages/dashboard/job_profile/JobProfileViewComponent';

const JobProfileView = (props) => {

  const { match } = props

  return (

    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='My Profile'
      />
      <Card
        style={{ minHeight: 360 }}
        title=''
      >
        <div style={{ paddingTop: 24 }}>
          <JobProfileViewComponent id={match.params.id} />
        </div>
      </Card>
    </div>

  );
}

export default JobProfileView;
