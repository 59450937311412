import React, { useEffect } from 'react';
import { PageHeader } from 'antd';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { Alert } from 'antd';

import ApplicationNewPageComponent from '../../../components/pages/dashboard/applications/ApplicationNewPageComponent';
import { getJob } from '../../../redux/actions/jobs';

const ApplicationNewPage = ({
  location, job, getJob
}) => {
  const params = queryString.parse(location.search);
  const jobId = params.jobId;

  useEffect(() => {
    getJob(jobId);
  }, [jobId, getJob]);

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16,
        }}
        title={(
          <div>
            <div>Apply for {job?.job_title ? job?.job_title : ''}</div>
            <div style={{ fontSize: 14 }}>To apply for this job, you must select a profile that you wish to send to the
              Job Advertiser and also any documents to be sent.
            </div>
          </div>
        )}
      />
      <Alert message="Pressing the back arrow will delete this record" type="warning" />
      <div style={{ paddingTop: 24 }}>
        <ApplicationNewPageComponent location={location}/>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ job: state.jobs.job });

const mapDispatchToProps = { getJob };

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationNewPage);
