const GET_JOBS = 'GET_JOBS';
const GET_JOB = 'GET_JOB';
const GET_JOB_STATUS_LIST = 'GET_JOB_STATUS_LIST';
const GET_SAVED_JOB_SEARCHES = 'GET_SAVED_JOB_SEARCHES';
const GET_SAVED_JOB_LIST = 'GET_SAVED_JOB_LIST';

export default {
  GET_JOBS,
  GET_JOB,
  GET_JOB_STATUS_LIST,
  GET_SAVED_JOB_SEARCHES,
  GET_SAVED_JOB_LIST,
}
