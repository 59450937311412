import React, { useState, useEffect } from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { setLanguageSelectId } from '../../../redux/actions/language';
import {
  DashboardOutlined,
  UserOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Select } from 'antd';
import { PageHeader, Button } from 'antd';

import ReactCountryFlag from 'react-country-flag';

const { Option } = Select;

const styles = {
  heading: {
    color: '#c30f48',
    fontWeight: 'bold',
  },
  headingText: {
    fontWeight: "bold",
    '@media (max-width: 420px)': {
      display: "none"
    }
  },
  headingLogo: {
    height: "30px",
    paddingLeft: 2,
    paddingRight: 20,
    '@media (min-width: 420px)': {
      display: "none",
    },
  },
}

function DashboardHeader(props) {
  const {
    setLanguageSelectIdFun,
    handleLogout,
    subscriptions,
    language,
    isAuthenticated,
    classes
  } = props;
  const [file_by, setFileby] = useState(false);
  const [month, setMonth] = useState('');

  useEffect(() => {
    setMonth(subscriptions?.subscription_price?.month_count || 1);
    setFileby(subscriptions?.subscription_product?.file_by);
  }, [subscriptions]);
  const history = useHistory();

  const handle_language_select = (value) => {
    setLanguageSelectIdFun(value);
  };

  const handleDashboard = async (event) => {
    history.push('/dashboard');
  };

  const handleUser = async (event) => {
    history.push('/profile/contact');
  };

  const handleSubscribeNow = async () => {
    history.push('/settings/subscription/1?tab=create_subscription');
  };

  return (
    <React.Fragment>
      <PageHeader
        ghost={false}
        className={classes.heading}
        title={
          <span className={classes.headingText}>
          <div>Welcome - and thanks for using Platinumjobseeker<sup>TM</sup></div>
          </span>
        }
        extra={
          isAuthenticated
            ? [
                // <Tooltip
                //   key="1"
                //   title={
                //     <FormattedMessage id="tooltip.title_switch_language" />
                //   }
                // >
                //   <Select
                //     defaultValue={language.language_current_ui}
                //     style={{ width: 180 }}
                //     onChange={handle_language_select}
                //   >
                //     <Option value="en" key="en">
                //       <ReactCountryFlag
                //         className="emojiFlag"
                //         countryCode="GB"
                //         style={{
                //           fontSize: '1.5em',
                //           lineHeight: '1.5em',
                //         }}
                //         aria-label="English Language"
                //       />{' '}
                //       English
                //     </Option>
                //     <Option value="zh" key="zh">
                //       <ReactCountryFlag
                //         countryCode="CN"
                //         style={{
                //           fontSize: '1.5em',
                //           lineHeight: '1.5em',
                //         }}
                //         aria-label="Chinese Traditional Language"
                //       />{' '}
                //       繁體中文
                //     </Option>
                //   </Select>
                // </Tooltip>,
                <Tooltip
                  key="2"
                  title={<FormattedMessage id="tooltip.title_dashboard" />}
                >
                  <Button
                    key="1"
                    type="primary"
                    onClick={handleDashboard}
                    icon={<DashboardOutlined />}
                  />
                </Tooltip>,
                <Tooltip
                  key="3"
                  title={<FormattedMessage id="tooltip.title_user_profile" />}
                >
                  <Button
                    key="2"
                    type="primary"
                    onClick={handleUser}
                    icon={<UserOutlined />}
                  />
                </Tooltip>,
                <Button
                  key="subscription"
                  type="primary"
                  disabled
                  // onClick={!file_by && handleSubscribeNow}
                >
                  {file_by ? `${file_by} ${month * 30}` : 'Free for limited time'}
                </Button>,
                <Tooltip
                  key="4"
                  title={<FormattedMessage id="tooltip.title_logout" />}
                >
                  <Button
                    key="3"
                    type="primary"
                    onClick={handleLogout}
                    icon={<LogoutOutlined />}
                  />
                </Tooltip>,
              ]
            : []
        }
      />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    app_state: state.app_state,
    subscriptions: state.subscriptions,
    language: state.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguageSelectIdFun: (language_id) => {
      dispatch(setLanguageSelectId(language_id));
    },
  };
};

const DashboardHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardHeader);

export default withRouter(withStyles(styles)(DashboardHeaderContainer));
