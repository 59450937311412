import { paymentType } from '../type';

const initialState = {
  subscriptionPrices: []
};

function payment(state = initialState, action) {
  switch (action.type) {
    case paymentType.GET_SUBSCRIPTION_PRICES:
      return {
        ...state,
        subscriptionPrices: action.subscriptionPrices
      }
    default:
      return state;
  }
}

export default payment;
