import React, { useState } from 'react';
import {notification, Spin, Select, Row, Col, Typography, Input, Space, Upload, message, Button} from 'antd';
import {InboxOutlined} from '@ant-design/icons';

import {createDocuementAPI} from '../../../../services/documents';
import {Utils} from '../../../../helpers';
import APIUtils from '../../../../helpers/api/utils';
import './DocumentCreateComponent.scss';

const {Text} = Typography;
const {TextArea} = Input;
const {Dragger} = Upload;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message
  });
};

const {Option} = Select;
const categories = [
  {
    id: 1,
    file_by: 'Curriculum vitae'
  },
  {
    id: 2,
    file_by: 'Application Cover Letter'
  }
];

const categoriesOptions = categories.map(item => (
  <Option value={item.id} key={item.id}>
    {item.file_by}
  </Option>
));

const DocumentCreateComponent = props => {
  const [loading, setLoading] = useState(false);
  const [saveDocument, setSaveDocument] = useState({documentCategory: 1});
  const [documentList, setDocumentList] = useState([]);
  const handleChangeCategory = value => {
    setSaveDocument(prev => ({
      ...prev,
      documentCategory: value
    }));
  };
  const token = APIUtils.getAccessToken();
  const uploadDocumentProps = {
    name: 'file',
    multiple: false,
    accept: '.doc, .docx, .pdf',
    action: `${Utils.serverUrl.API_BASE_URL}/applicant/v1/upload`,
    headers: {Authorization: `Bearer ${token}`},

    fileList: documentList,
    onChange(info) {
      const {status} = info.file;
      setDocumentList([info.file]);
      if (status !== 'uploading') {
        setSaveDocument(prev => ({
          ...prev,
          fileId: JSON.stringify({
            name: info.file.name,
            path: info.file.response.Location
          })
        }));
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        if (saveDocument.fileId) {
          setDocumentList([JSON.parse(saveDocument.fileId)]);
        }
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const handleChangeText = e => {
    const {value, id} = e.target;
    setSaveDocument(prev => ({
      ...prev,
      [id]: value
    }));
  };

  const handleCreateDocument = () => {
    setLoading(true);
    createDocuementAPI(saveDocument)
      .then(data => {
        setLoading(false);
        openNotificationWithIcon('success', 'Save Document', 'Your data has been saved successfully.');
      })
      .catch(erorr => {
        setLoading(false);
        openNotificationWithIcon('error', 'Save Document', 'An error occurred in data saving.');
      });
  };

  return (
    <React.Fragment>
      <Spin spinning={loading}>
        <Row gutter={[24, 16]}>
          <Col span={12}>
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
              <Col span={12}>
                <Text>Document Category:</Text>
              </Col>
              <Col span={12}>
                <Select style={{ width: '100%' }} defaultValue={1} onChange={handleChangeCategory}>
                  {categoriesOptions}
                </Select>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
              <Col span={12}>
                <Text>Document Title:</Text>
              </Col>
              <Col span={12}>
                <Input id='documentTitle' onChange={handleChangeText} />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Space size={10} direction='vertical' style={{width: '100%'}}>
                  <Text>Long Description:</Text>
                  <TextArea id='longDescription' rows={5} onChange={handleChangeText} />
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={24} style={{ marginBottom: 16 }}>
                <Text>Document Upload:</Text>
              </Col>
              <Col span={12}>
                <Dragger {...uploadDocumentProps}>
                  <p className='ant-upload-drag-icon'>
                    <InboxOutlined />
                  </p>
                  <p className='ant-upload-hint' style={{
                    fontStyle: 'italic',
                    padding: 10
                  }}>
                    Please upload your document.
                  </p>
                </Dragger>
              </Col>
              {
                saveDocument.documentCategory === 2 && (
                  <Col span={24} style={{ marginTop: 16 }}>
                    <Button
                      href="https://pjs-documents-public.s3.amazonaws.com/job-description-template-PJS02082021.docx"
                      type="primary"
                    >
                      Click here for cover letter template
                    </Button>
                  </Col>
                )
              }
            </Row>
          </Col>
          <Col span={24} style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
            <Button type="primary" onClick={handleCreateDocument}>Add Document</Button>
          </Col>
        </Row>
      </Spin>
    </React.Fragment>
  );
};

export default DocumentCreateComponent;
