import {Utils} from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const getDocumentsListAPI = () => {
  const path = `/applicant/v1/documents`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const getDocuementAPI = id => {
  const path = `/applicant/v1/documents/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const createDocuementAPI = params => {
  const path = `/applicant/v1/documents/create`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

export {getDocumentsListAPI, getDocuementAPI, createDocuementAPI};
