import React from 'react';
import { PageHeader } from 'antd';
import DashboardPageComponent from '../../components/pages/dashboard/dashboard/DashboardPageComponent';

function DashboardPage() {
  return (
    <div style={{ padding: 8, background: '#fff', minHeight: 360, height: '100%' }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Dashboard Page'
      />
      <DashboardPageComponent />
    </div>
  );
}

export default DashboardPage;
