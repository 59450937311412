import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Button, Typography } from 'antd';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import { getCompanies } from '../../../../redux/actions/companies';
import listHOC from '../../../common/listHOC';
import CountrySelectComponent from '../../../common/CountrySelectComponent';
import withStyles from 'react-jss';
import CompanyListView from './CompanyListView';

const { Text } = Typography;

const styles = {
  listView: {
    border: '1px solid #EBEDF0',
    marginBottom: 20,
    maxHeight: 550,
    overflow: 'auto',
    fontSize: 16,
  },
  listItem: {
    borderBottom: '1px solid #EBEDF0',
    padding: '15px 20px',
    cursor: 'pointer',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: 24,
    marginBottom: 0,
    color: 'var(--global--color-brand)',
    fontWeight: 700,
    marginRight: 15,
  },
  subTitle: { fontWeight: 'bold' },
  wrapper: { marginBottom: 25 },
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  formLabel: {
    padding: 5,
    display: 'block',
  }
};

const CompanySearchPageComponent = (props) => {
  const {
    searchCompanies,
    companies,
    classes,
  } = props;

  const [searchParams, setSearchParams] = useState({
    userinput_classification: [],
    userinput_country: '',
    userinput_region: '',
    userinput_keywords: '',
  });
  useEffect(() => {
    searchCompanies(searchParams);
  }, []);

  const handleChange = event => {
    const { id, value } = event.target;
    setSearchParams(prev => ({
      ...prev,
      [id]: value,
    }));
  };
  const handleChangeCountry = value => {
    setSearchParams(prev => ({
      ...prev,
      userinput_country: value ?? '',
    }));
  };
  const handlerSearchCompanies = () => {
    searchCompanies(searchParams);
  };
  return (
    <React.Fragment>
      <Row gutter={32} span={24} style={{ marginBottom: 30 }}>
        <Col span={5}>
          <Text className={classes.formLabel}>What</Text>
          <Input id='userinput_keywords' placeholder='Enter keywords' allowClear onChange={handleChange} />
        </Col>
        <Col span={4}>
          <Text className={classes.formLabel}>Region</Text>
          <Input id='userinput_region' placeholder='Enter Region' allowClear onChange={handleChange} />
        </Col>
        <Col span={7}>
          <Text className={classes.formLabel}>Country</Text>
          <Row>
            <CountrySelectComponent
              id="street_address_country"
              value={searchParams.userinput_country}
              handleChange={handleChangeCountry} />
            <Button type='primary' style={{ marginLeft: 5 }} icon={<SearchOutlined />} onClick={handlerSearchCompanies}>
              Search
            </Button>
          </Row>
        </Col>
      </Row>
      <CompanyListView companies={companies} />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    companies: state.companies.companies,
    subscriptions: state.subscriptions,
  };
};

const mapDispatchToProps = { searchCompanies: getCompanies };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(listHOC(CompanySearchPageComponent)));
