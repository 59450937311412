import React from 'react';
import { PageHeader } from 'antd';
import CompanyDetailComponent from '../../../components/pages/dashboard/companies/CompanyDetailComponent';

function CompanyDetailPage (props) {
  const { match: { params } } = props;
  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360,
      height: '100%'
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='Advertiser View'
      />
      <CompanyDetailComponent id={params.company_id} />
    </div>
  )
}

export default CompanyDetailPage;