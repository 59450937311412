import { Col, Row, Spin, Tabs, Button, Space, Typography } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getApplications } from '../../../../redux/actions/applications';
import JobDetailTap from './ApplicationViewJobTap/JobDetail';
import ApplicantProfileTap from './ApplicationViewJobTap/ApplicantProfileTap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {
  convertToRaw, EditorState, ContentState,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { updateApplicationsAPI } from '../../../../services/applications';
import { openNotificationWithIcon } from '../../../../redux/utils';

const {TabPane} = Tabs;
const {Text} = Typography;

const ApplicationViewPageComponent = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { application } = useSelector(state => ({ application: state.applications }));
  const [currentApplication] = application ?? [];
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [htmlText, setHtmlText] = useState();

  const fetchApplication = useCallback(async (id) => {
    try {
      dispatch(getApplications({ id }));
      setLoading(false);
      setError(null);
    } catch (e) {
      setError('Unable to fetch', e);
    }
  }, [dispatch]);

  useEffect(() => {
    if (currentApplication?.applicantNotes) {
      const contentBlock = htmlToDraft(currentApplication?.applicantNotes);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
      }
    }
  }, [currentApplication]);
  useEffect(() => {
    fetchApplication(id);
  }, [fetchApplication, id]);

  const { applicantProfileId = {}, job } = currentApplication ?? {};

  const handleNoteSave = () => {
    setLoading(true);
    updateApplicationsAPI(id, { ...currentApplication, applicantNotes: htmlText })
      .then(() => {
        openNotificationWithIcon('success', 'Save Notes', 'Notes saved successfully');
        setLoading(false);
      })
      .catch(() => {
        openNotificationWithIcon('error', 'Save Notes', 'An error occurred in notes saving');
        setLoading(false);
      });
  };

  const onEditorStateChange = (edit) => {
    setEditorState(edit);

    setHtmlText(draftToHtml(convertToRaw(edit.getCurrentContent())));
  };

  return (
    <Spin spinning={loading}>
      <Row gutter={[32, 32]}>
        <Col span={12}>
          <Tabs defaultActiveKey='1'>
            <TabPane tab='Job Details' key='1'>
              <JobDetailTap jobData={job} />
            </TabPane>
            <TabPane tab='Applicant Profile' key='3'>
              <ApplicantProfileTap applicantProfile={applicantProfileId} />
            </TabPane>
          </Tabs>
        </Col>
        <Col span={12}>
          <Space direction='vertical' style={{marginTop: 10}}>
            <Text style={{fontSize: 15, fontWeight: 'bold'}}>Application Notes</Text><em>(These are your private notes – only you can see them)</em>
            <Editor
              editorState={editorState}
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'history'],
                inline: {
                  options: ['bold', 'italic', 'underline']
                }
              }}
            />
          </Space>
          <div style={{marginTop: 10, justifyContent: 'flex-end', display: 'flex'}}>
            <Button type='primary' onClick={handleNoteSave}>
              Save
            </Button>
          </div>
        </Col>
      </Row>
    </Spin>
  );

};

export default ApplicationViewPageComponent;
