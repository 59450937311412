import jobsType from '../type/jobs';

const initialState = {
  jobs: [],
  statusList: [],
  savedJobSearches: [],
  job: {},
  savedJobs: [],
  savedJobsCount: 0,
};

function jobs(state = initialState, action) {
  switch (action.type) {
    case jobsType.GET_JOBS:
      return {
        ...state,
        jobs: action.jobs
      };
    case jobsType.GET_JOB:
      return {
        ...state,
        job: action.job,
      };
    case jobsType.GET_JOB_STATUS_LIST:
      return {
        ...state,
        statusList: action.data
      }
    case jobsType.GET_SAVED_JOB_SEARCHES:
      return {
        ...state,
        savedJobSearches: action.data
      }
    case jobsType.GET_SAVED_JOB_LIST:
      return {
        ...state,
        savedJobs: action.data,
        savedJobsCount: action.data.length,
      };
    default:
      return state;
  }
}

export default jobs;
