import React from 'react';
import {
  Card
} from 'antd';

import PageTitle from '../../../components/page-title/page-title';
import DocumentListViewComponent from '../../../components/pages/dashboard/MyDocument/DocumentListViewComponent';

const MydocumentsList = (props) => {

  const { match } = props

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageTitle title='My Documents'>
        Here you can see a list of documents that you have uploaded to your system. You can view and delete the documents as well as add new ones.
      </PageTitle>
      <Card
        style={{ minHeight: 360 }}
        title=''
      >
        <div style={{ paddingTop: 24 }}>
          <DocumentListViewComponent id={match.params.id} />
        </div>
      </Card>
    </div>

  );
}

export default MydocumentsList;
