import React, { Component } from 'react';
import { Card, PageHeader } from 'antd';

import ApplicationListPageComponent from '../../../components/pages/dashboard/applications/ApplicationListPageComponent';

class ApplicationListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title='Current Job Applications'
        />
        {/* <Card style={{ minHeight: 360 }} title='Current Application List'> */}
          <ApplicationListPageComponent />
        {/* </Card> */}
      </div>

    );
  }
}

export default ApplicationListPage;
